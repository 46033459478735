
import { assertInInjectionContext, inject, type Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, type Params } from '@angular/router';
import type { InfrontSDK } from '@infront/sdk';
import type { Logger } from '@vwd/ngx-logging';
import { map } from 'rxjs';
import { environment } from '../../environments/environment';


// injects a router param as a signal
export function injectParams(
  key: string,
): Signal<string | undefined> {
  assertInInjectionContext(injectParams);
  const route = inject(ActivatedRoute);
  const getParam = (params: Params) => {
    return (params?.[key]) as string ?? undefined;
  };
  return toSignal(route.params.pipe(map(getParam)), { requireSync: true });
}


// send in fields and a symbol, get back an object with the fields as keys and the values typed as defined in SDK
export function typedFields<T extends InfrontSDK.SymbolField>(
  fields: T[],
  symbol: InfrontSDK.SymbolData
): { [K in T]: InfrontSDK.SymbolFieldTypeBase[K] } {
  return fields.reduce((acc, field) => {
    acc[field] = symbol.get(field) as InfrontSDK.SymbolFieldTypeBase[T];
    return acc;
  }, {} as { [K in T]: InfrontSDK.SymbolFieldTypeBase[K] });
}


/**
* Finds the last index in `value` of one of the specified values in
* `valuesToMatch`, or `-1` if no match was found.
*
* @param value The string or array to search.
* @param valuesToMatch Any matcher object that has an `includes` method, for
*   example an array of values to search for, or a single string of characters
*   to search for.
*/
export function lastIndexOfAny<T>(value: ArrayLike<T>, valuesToMatch: { includes(value: T): boolean }): number {
  for (let i = value.length - 1; i >= 0; i--) {
    if (valuesToMatch.includes(value[i])) {
      return i;
    }
  }
  return -1;
}

// get the type inside a signal
export type UnwrapSignal<T> = T extends Signal<infer U> ? U : T;

// get the item type inside a signal array
export type UnwrapSignalItem<T> = UnwrapSignal<T> extends (infer U)[] | undefined ? U : never;

export interface Instrument {
  feed: number;
  ticker: string;
}

export const isInstrument = (instrument: unknown): instrument is Instrument => {
  return instrument != undefined && (instrument as Instrument).ticker != undefined && (instrument as Instrument).feed != undefined;
};

export type DotToDoubleUnderscore<T extends string> = T extends `${infer Left}.${infer Right}`
  ? `${Left}__${DotToDoubleUnderscore<Right>}`
  : T;

export function translateDotNotationToDoubleUnderscore<T extends string>(input: T): DotToDoubleUnderscore<T> {
  return input.replace(/\./g, '__') as DotToDoubleUnderscore<T>;
}


export function translateObjectKeys<T extends Record<string, unknown>>(obj: T, translateKeyFn: (key: string) => string): { [K in keyof T as DotToDoubleUnderscore<string & K>]: T[K] } {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return Object.keys(obj).reduce((acc, key) => {
    const translatedKey = translateKeyFn(key);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    acc[translatedKey] = obj[key]; // Assign the original value to the translated key
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return acc;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }, {} as any);
}


export const sdkOnError = (logger: Logger) => {
  return (e: InfrontSDK.ErrorBase) => {
    logger.error(e.title, e.parameters?.code, e.parameters?.msg);
    if (environment.development) {
      logger.error('Watchlist SDK Error', e);
      logger.error(Error().stack);
    }
  };
};


// mergeObjectArrays overloads and helpers

export type FlattenIntersection<T> = (T extends infer U ? { [K in keyof U]: U[K] } : never) extends infer O
  ? { [K in keyof O]: O[K] }
  : never;

export function mergeObjectArrays<
  T1 extends object[],
  T2 extends object[]
>(
  nestedArrayGroups: [T1, T2][]
): Array<FlattenIntersection<T1[number] & T2[number]>>;

export function mergeObjectArrays<
  T1 extends object[],
  T2 extends object[],
  T3 extends object[]
>(
  nestedArrayGroups: [T1, T2, T3][]
): Array<FlattenIntersection<T1[number] & T2[number] & T3[number]>>;

export function mergeObjectArrays<
  T1 extends object[],
  T2 extends object[],
  T3 extends object[],
  T4 extends object[]
>(
  nestedArrayGroups: [T1, T2, T3, T4][]
): Array<FlattenIntersection<T1[number] & T2[number] & T3[number] & T4[number]>>;

export function mergeObjectArrays(
  nestedArrayGroups: object[][][]
): object[] {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return nestedArrayGroups.map((arrayGroup) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const mergedObject: any = {};
    arrayGroup.forEach((objectArray) => {
      objectArray.forEach((objectItem) => {
        Object.assign(mergedObject, objectItem);
      });
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return mergedObject;
  });
}
