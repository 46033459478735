import { ChangeDetectionStrategy, Component, computed, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { InfrontSDK, type Infront } from '@infront/sdk';
import { filterUndefined } from '@infront/webtrader-app/app/util/rxjs';
import { AnalyticsSectorInformationWidget, AnalyticsSectorInformationWidgetOptions } from '@infront/wtk/widgets/analytics-sector-information';
import { RegionSelectWidget, RegionSelectWidgetOptions } from '@infront/wtk/widgets/region-select';
import { SubSectorSelectWidget, SubSectorSelectWidgetOptions } from '@infront/wtk/widgets/subsector-select';
import { combineLatest, switchMap } from 'rxjs';
import { SdkService } from '../../services/sdk.service';
import { currencies } from '../../shared/analytics-sector-information/analytics-sector-information.model';
import { injectParams } from '../../shared/util';
import { injectParams$, mergeObjectArrays } from '../../shared/util-rxjs';
import { createWidget } from '../../shared/wtk-widget-wrapper/wtk-widget-wrapper.model';
import { globalKeyfigureSource, globalMinimumMarketCap } from '../../shared/models/settings';
@Component({
  selector: 'wt-company-sector',
  templateUrl: './company-sector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanySectorComponent {
  readonly isin = injectParams('isin');
  readonly sdkService = inject(SdkService);
  readonly column1Width = 500;
  readonly column2Width = 500;
  readonly currencies = currencies;


  private gicsSector$(isin: string) {
    return this.sdkService.cdapiMappedRequest$(InfrontSDK.CoreDataAPI.cdapiGetEquityClassificationGicsSectors,
      { isin, fields: ['gics_sector.code'] });
  }

  private gicsSubIndustry$(isin: string) {
    return this.sdkService.cdapiMappedRequest$(InfrontSDK.CoreDataAPI.cdapiGetEquityClassificationGicsSubIndustries,
      { isin, fields: ['gics_sub_industry.code'] });
  }

  private readonly gicsData = toSignal(injectParams$('isin').pipe(
    filterUndefined(),
    switchMap(isin => combineLatest([this.gicsSector$(isin), this.gicsSubIndustry$(isin)]).pipe(
      mergeObjectArrays()
    ))));


  //similar sectors
  readonly similarSectorsWidgetRef = signal<AnalyticsSectorInformationWidget | undefined>(undefined);
  readonly similarSectorsSubSectorSelectWidgetRef = signal<SubSectorSelectWidget | undefined>(undefined);
  readonly similarSectorsRegionSelectWidgetRef = signal<RegionSelectWidget | undefined>(undefined);

  readonly similarSectorsRegionSelectWidget = computed(() => createWidget(RegionSelectWidget, RegionSelectWidgetOptions, {
    selectedRegionCode: 'INTERNATIONAL', // default region
    instrument: { isin: this.isin() } as Infront.Instrument,
    onRegionSelected: sectorRegion => {
      this.similarSectorsWidgetRef()?.modify({ sectorRegion });
    }
  }));

  readonly similarSectorsSubSectorSelectWidget = computed(() => createWidget(SubSectorSelectWidget, SubSectorSelectWidgetOptions, {

    instrument: { isin: this.isin() } as Infront.Instrument,
    onSectorSelected: subSectorSelection => this.similarSectorsWidgetRef()?.modify(subSectorSelection as Partial<AnalyticsSectorInformationWidgetOptions>)
  }));

  readonly similarSectorsWidget = computed(() => {
    const gicsSubIndustryCode = this.gicsData()?.['gics_sub_industry.code'];
    if (!gicsSubIndustryCode) {
      return undefined;
    }
    return createWidget(
      AnalyticsSectorInformationWidget,
      AnalyticsSectorInformationWidgetOptions,
      {
        gicsSubIndustryCode,
        instrument: { isin: this.isin() } as Infront.Instrument,
        minimumMarketCap: globalMinimumMarketCap,
        source: globalKeyfigureSource,
      });
  });


  // biggest sectors
  readonly biggestSectorsWidgetRef = signal<AnalyticsSectorInformationWidget | undefined>(undefined);
  readonly biggestSectorsSubSectorSelectWidgetRef = signal<SubSectorSelectWidget | undefined>(undefined);
  readonly biggestSectorsRegionSelectWidgetRef = signal<RegionSelectWidget | undefined>(undefined);

  readonly biggestSectorsRegionSelectWidget = computed(() => createWidget(RegionSelectWidget, RegionSelectWidgetOptions, {
    selectedRegionCode: 'INTERNATIONAL', // default region
    instrument: { isin: this.isin() } as Infront.Instrument,
    onRegionSelected: sectorRegion => {
      this.biggestSectorsWidgetRef()?.modify({ sectorRegion });
    }
  }));

  readonly biggestSectorsSubSectorSelectWidget = computed(() => createWidget(SubSectorSelectWidget, SubSectorSelectWidgetOptions, {
    instrument: { isin: this.isin() } as Infront.Instrument,
    onSectorSelected: subSectorSelection => this.biggestSectorsWidgetRef()?.modify(subSectorSelection as Partial<AnalyticsSectorInformationWidgetOptions>)
  }));

  readonly biggestSectorsWidget = computed(() => {
    const gicsSectorCode = this.gicsData()?.['gics_sector.code'];
    if (!gicsSectorCode) {
      return undefined;
    }
    return createWidget(
      AnalyticsSectorInformationWidget,
      AnalyticsSectorInformationWidgetOptions,
      {
        gicsSectorCode,
        minimumMarketCap: globalMinimumMarketCap,
        source: globalKeyfigureSource,
      });
  });

}

