
import { inject } from '@angular/core';
import { ActivatedRoute, type Params } from '@angular/router';
import { map, type Observable } from 'rxjs';



// injects a router param as an observable
export function injectParams$(
  key: string,
): Observable<string | undefined> {
  const route = inject(ActivatedRoute);
  return route.params.pipe(
    map((params: Params) => (params?.[key]) as string ?? undefined)
  );
}

export function mergeObjectArrays<
  T1 extends object,
  T2 extends object,
  T3 extends object = object,
  T4 extends object = object
>(): (source: Observable<[T1[], T2[], (T3[] | undefined)?, (T4[] | undefined)?]>) => Observable<T1 & T2 & Partial<T3> & Partial<T4>> { // NOSONAR
  return (source: Observable<[T1[], T2[], (T3[] | undefined)?, (T4[] | undefined)?]>) => source.pipe( // NOSONAR
    map(([part1, part2, part3, part4]) => {
      const combined = {
        ...part1.reduce((acc, obj) => ({ ...acc, ...obj }), {} as T1),
        ...part2.reduce((acc, obj) => ({ ...acc, ...obj }), {} as T2),
        ...(part3 ? part3.reduce((acc, obj) => ({ ...acc, ...obj }), {} as Partial<T3>) : {}),
        ...(part4 ? part4.reduce((acc, obj) => ({ ...acc, ...obj }), {} as Partial<T4>) : {}),
      };
      return combined;
    }),
  );
}

