<h3>
  Home Placeholder
</h3>

<p>

  Open a company page:


  <a [routerLink]="['/']" [queryParams]="{ isin: 'SE0000108656', page: 'overview' }">
    home?isin=SE0000108656&page=overview
  </a>
</p>

