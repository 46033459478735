import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';

import { SectorsComponent } from './sectors.component';
import { AnalyticsSectorInformationModule } from '../shared/analytics-sector-information/analytics-sector-information.module';

@NgModule({
  exports: [
    SectorsComponent,
  ],
  declarations: [
    SectorsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    I18nTranslateModule.forResourceSet({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['de', 'en', 'no']
    }),

    AnalyticsSectorInformationModule,
  ],
  providers: [],
})
export class SectorsModule { }
