import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';
import { WTKWidgetWrapperModule } from '../wtk-widget-wrapper/wtk-widget-wrapper.module';
import { AnalystsComponent } from './analysts.component';

@NgModule({
  exports: [
    AnalystsComponent,
  ],
  declarations: [
    AnalystsComponent,
  ],
  imports: [
    CommonModule,
    WTKWidgetWrapperModule,
    MatIconModule
  ],
})
export class AnalystsModule { }
