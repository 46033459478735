import { inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { InfrontSDK } from '@infront/sdk';
import { BehaviorSubject, debounceTime, distinctUntilChanged, map, of, switchMap, tap } from 'rxjs';
import { CountryFlagService } from '../services/country-flag.service';
import { SdkService } from '../services/sdk.service';
import { SearchStringMinLength } from './search.model';

@Injectable()
export class SearchService {
  private readonly sdkService: SdkService = inject(SdkService);
  private readonly countryFlagService: CountryFlagService = inject(CountryFlagService);

  private searchAction = new BehaviorSubject<string>(''); // hold search string as a subject because we want to debounce and trigger sdkService observable on change
  // and use toSignal only once to create a signal from the observable

  search(search_string: string): void {
    this.searchAction.next(search_string);
  }

  readonly searchResult = toSignal(this.searchAction.pipe(
    distinctUntilChanged(),
    switchMap((search_string) => {
      if (search_string.length < SearchStringMinLength) {
        return of([]);
      }
      return of(search_string).pipe(
        debounceTime(300),
        switchMap((search_string) =>
          this.sdkService.cdapiMappedRequest$(
            InfrontSDK.CoreDataAPI.cdapiGetEquityListings,
            {
              search_string,
              preferred_trading_venue_strategy_code: 'PRIMARY_TRADING_VENUE',
              is_primary_instrument: true,
              fields: [
                'instrument.common.isin',
                'country.code',
                'company_basic.name',
                'issuer.common.name',
                'listing.common.name',
              ],
            } as const
          ).pipe(
            tap((data) => console.log(data)), // debug
            map(items => items.map((item) => ({ ...item, flagPath: this.countryFlagService.getCountryFlagPath(item['country.code'], 16) })))
          )
        )
      );
    })
  ));
}
