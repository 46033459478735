import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { KeycloakAuthModule } from '@vwd/keycloak-auth-angular';
import { I18nPipesModule } from '@vwd/ngx-i18n/pipes';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';
import { LOGGING_CONFIG_TOKEN, type LoggingConfig } from '@vwd/ngx-logging';
import * as HighchartsMore from 'highcharts/highcharts-more';
import * as Highcharts from 'highcharts/highstock';
import * as HighchartsIndicatorsAll from 'highcharts/indicators/indicators-all';
import * as HighchartsAnnotationsAdvanced from 'highcharts/modules/annotations-advanced';
import * as HighchartsDragPanes from 'highcharts/modules/drag-panes';
import * as HeikinAshiModule from 'highcharts/modules/heikinashi';
import * as HollowCandlestickModule from 'highcharts/modules/hollowcandlestick';
import * as HighchartsStockTools from 'highcharts/modules/stock-tools';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LayoutModule } from './layout/layout.module';
import { SearchModule } from './search/search.module';
import { SectorsModule } from './sectors/sectors.module';
import { GlobalErrorHandler } from './services/error-handler.service';
import { AnalystsModule } from './shared/analysts/analysts.module';
import { WatchlistsModule } from './watchlists/watchlists.module';

function getLogging(): LoggingConfig {
  // TODO remove console.log after environment clarified
  console.log('app.module getLogging', environment.envName, { enabled: true, levels: environment.logging[environment.envName] });
  return { enabled: true, levels: environment.logging[environment.envName] } as LoggingConfig;
}

type HighchartsFunction = (h: unknown) => void;
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,

    // Angular Materials
    MatSnackBarModule,
    MatButtonModule,
    MatDialogModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    SectorsModule, // current lazyloading fails to load dependencies
    WatchlistsModule, // current lazyloading fails to load dependencies
    AnalystsModule, // current lazyloading fails to load dependencies
    KeycloakAuthModule.forRoot(),
    I18nTranslateModule.forRoot({
      // missingTranslationHandler: DefaultMissingTranslationHandler,
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`../i18n/${locale}.json`),
      supportedLocales: ['en'],
      defaultLocale: 'en',
    }),
    I18nPipesModule,
    LayoutModule,
    SearchModule,
  ],
  providers: [
    {
      provide: LOGGING_CONFIG_TOKEN,
      useFactory: getLogging,
      deps: []
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    this.initHighcharts();
  }

  private initHighcharts(): void {
    // Set window.Highcharts from imported Highcharts
    window.Highcharts = Highcharts;

    // Initialize additional Highcharts modules
    (HighchartsMore as unknown as HighchartsFunction)(Highcharts);
    (HighchartsIndicatorsAll as unknown as HighchartsFunction)(Highcharts);
    (HighchartsDragPanes as unknown as HighchartsFunction)(Highcharts);
    (HighchartsAnnotationsAdvanced as unknown as HighchartsFunction)(Highcharts);
    (HighchartsStockTools as unknown as HighchartsFunction)(Highcharts);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    HollowCandlestickModule(Highcharts);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    HeikinAshiModule(Highcharts);

  }

}
