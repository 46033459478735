import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'wt-analytics-window',
  templateUrl: './analytics-window.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnalyticsWindowComponent {
  @Input() title = '';
  @Input() width = 300;
  @Input() height = 200;

}
