<div class="wt-company-overview__widgets">
  <!-- left side windows -->
  <div class="wt-company-overview__widgets__col">

    <!-- Info Window with tabs -->
    <wt-analytics-window [title]="'COMPANY.OVERVIEW.INFO.INFO' | translate" [width]="column1Width" [height]="345">

      <wt-analytics-tabs

        [tabs]="['COMPANY.OVERVIEW.INFO.MARKET' | translate, 'COMPANY.OVERVIEW.INFO.SHAREHOLDERS' | translate, 'COMPANY.OVERVIEW.INFO.BOARD' | translate, 'COMPANY.OVERVIEW.INFO.OFFICERS' | translate, 'COMPANY.OVERVIEW.INFO.DESCRIPTION' | translate]"
        (selectedTabChange)="onSelectedMarketTabChange($event)"></wt-analytics-tabs>

      <!-- Market Tab (Info Window) -->
      <ng-container *ngIf="selectedMarketTabIndex === 0">
        <wt-instrument-info [isin]="isin()" [marketCapEur]="marketCapEur()"></wt-instrument-info>
        <wt-wtk-widget-wrapper
          *ngIf="chartWidget()"
          [widget]="chartWidget()!"
          (widgetRefChange)="chartWidgetRef.set($event)"
          class="wt-company-overview__info__chart">
        </wt-wtk-widget-wrapper>

        <div class="wt-flex-row wt-flex-align-center">
          <div class="wt-flex-column wt-flex-shrink">
            <wt-analytics-tabs [tabs]="chartSelectablePeriods" tabStyle="chart"
              (selectedTabChange)="onSelectedChartTimePeriodChange($event)"></wt-analytics-tabs>
          </div>
        </div>
      </ng-container>

      <!-- Shareholders Tab (Info Window) -->
      <div *ngIf="selectedMarketTabIndex === 1" class="wt-company-overview__info-widget">
        <wt-wtk-widget-wrapper
          [widget]="shareholdersWidget()">
        </wt-wtk-widget-wrapper>
      </div>

      <!-- Board Members Tab (Info Window) -->
      <div *ngIf="selectedMarketTabIndex === 2" class="wt-company-overview__info-widget"
        [attr.wt-metadata-source]="'equity/companies?isin=' + isin() + '&fields=company_position.board.vice_chairman%2Ccompany_position.board.members'">
        <wt-wtk-widget-wrapper
          [widget]="boardMembersWidget()">
        </wt-wtk-widget-wrapper>
      </div>

      <!-- Top Officers Tab (Info Window) -->
      <div *ngIf="selectedMarketTabIndex === 3" class="wt-company-overview__info-widget"
        [attr.wt-metadata-source]="'equity/companies?isin=' + isin() + '&fields=company_position.management.ceo%2Ccompany_position.management.cfo%2Ccompany_position.management.chro'">
        <wt-wtk-widget-wrapper class="wt-company-overview__widget"
          [widget]="topOfficersWidget()">
        </wt-wtk-widget-wrapper>
      </div>

      <!-- Company Description Tab (Info Window) -->
      <div *ngIf="selectedMarketTabIndex === 4" class="wt-company-overview__info-widget"
        [attr.wt-metadata-source]="'equity/companies?isin=' + isin() + '&fields=company_basic.description'">
        <wt-wtk-widget-wrapper class="wt-company-overview__widget"
          [widget]="companyDescriptionWidget()">
        </wt-wtk-widget-wrapper>
      </div>

    </wt-analytics-window>

    <wt-analytics-window [title]="'GPRV'" [width]="column1Width" [height]="580" class="wt-company-overview__info__gprv">
      <div class="wt-analytics-window__gprv">
        <div class="wt-company-overview__info__gprv-score">
          <wt-wtk-widget-wrapper
            [widget]="analyticsGprvScoreCardWidget()">
          </wt-wtk-widget-wrapper>
        </div>
      </div>

      <div class="wt-company-overview__info__gprv__chart">
        <wt-wtk-widget-wrapper
          [widget]="analyticsGprvWidget()">
        </wt-wtk-widget-wrapper>
      </div>
    </wt-analytics-window>
  </div>

  <!-- right side windows -->
  <div class="wt-company-overview__widgets__col">
    <wt-analytics-window [title]="'Estimates Overview'" [width]="column2Width" [height]="95">
      <div class="wt-company-overview__estimates-overview">
        <wt-wtk-widget-wrapper [widget]="estimatesBarWidget()">
        </wt-wtk-widget-wrapper>
      </div>
    </wt-analytics-window>

    <wt-analytics-window [title]="'Key Figures'" [width]="column2Width" [height]="398">
      <wt-wtk-widget-wrapper
        [widget]="keyFiguresWidget()">
      </wt-wtk-widget-wrapper>
    </wt-analytics-window>

    <wt-analytics-window [title]="'Profitability'" [width]="column2Width" [height]="430">
      <div class="wt-flex-row wt-flex-collapse">
        <div class="wt-flex-column">
          <wt-wtk-widget-wrapper
            *ngIf="miniChartSalesIncomeMarginWidget() as miniChartSalesIncomeMarginWidget"
            [widget]="miniChartSalesIncomeMarginWidget">
          </wt-wtk-widget-wrapper>
        </div>

        <div class="wt-flex-column">
          <wt-wtk-widget-wrapper
            *ngIf="miniChartPEWidget() as miniChartPEWidget"
            [widget]="miniChartPEWidget">
          </wt-wtk-widget-wrapper>
        </div>
      </div>
    </wt-analytics-window>
  </div>
</div>