<div class="wt-company-tabs">
  <nav class="wt-company-tabs__main">
    <ul class="wt-list-inline">
      <li class="wt-dashboard-tabs__item" routerLinkActive="wt-dashboard-tabs__item--selected">
        <a class="wt-link wt-dashboard-tabs__item-label" routerLink="overview">{{ 'COMPANY.TABS.OVERVIEW' | translate
          }}</a>
      </li>
      <li class="wt-dashboard-tabs__item" routerLinkActive="wt-dashboard-tabs__item--selected">
        <a class="wt-dashboard-tabs__item-label" routerLink="keyfigures">{{
          'COMPANY.TABS.KEY_FIGURES' | translate }}</a>
      </li>

      <li class="wt-dashboard-tabs__item" routerLinkActive="wt-dashboard-tabs__item--selected">
        <a class="wt-dashboard-tabs__item-label" routerLink="estimates">{{ 'COMPANY.TABS.ESTIMATES' | translate }}</a>
      </li>
      <li class="wt-dashboard-tabs__item" routerLinkActive="wt-dashboard-tabs__item--selected">
        <a class="wt-dashboard-tabs__item-label" routerLink="sector">{{ 'COMPANY.TABS.SECTOR' | translate }}</a>
      </li>
      <li class="wt-dashboard-tabs__item" routerLinkActive="wt-dashboard-tabs__item--selected">
        <a class="wt-dashboard-tabs__item-label" routerLink="gprv">{{ 'COMPANY.TABS.GPRV' | translate }}</a>
      </li>
      <li class="wt-dashboard-tabs__item" routerLinkActive="wt-dashboard-tabs__item--selected">
        <a class="wt-dashboard-tabs__item-label" routerLink="financial-statements">{{
          'COMPANY.TABS.FINANCIAL_STATEMENTS' | translate }}</a>
      </li>
      <li class="wt-dashboard-tabs__item" routerLinkActive="wt-dashboard-tabs__item--selected">
        <a class="wt-dashboard-tabs__item-label" routerLink="esg">{{ 'COMPANY.TABS.ESG' | translate }}</a>
      </li>
    </ul>
  </nav>
</div>
