<!-- left side windows -->
<div class="wt-tabs-content">
  <div class="wt-tabs-content__col">
    <wt-analytics-window [title]="'Estimates Overview'" [width]="column1Width" [height]="90">
      <div class="wt-company-overview__estimates-overview">
        <wt-wtk-widget-wrapper [widget]="estimatesBarWidget()">
        </wt-wtk-widget-wrapper>
      </div>
    </wt-analytics-window>

    <wt-analytics-window [title]="'Key Figures'" [width]="column1Width" [height]="605">
      <wt-wtk-widget-wrapper [widget]="keyFiguresWidget()">
      </wt-wtk-widget-wrapper>
    </wt-analytics-window>
  </div>
  <!-- right side windows -->
  <div class="wt-tabs-content__col">
    <wt-analytics-window [title]="'Target Price'" [width]="column2Width" [height]="480">
      <div class="wt-company-overview__target-price">
        <wt-wtk-widget-wrapper class="wt-company-overview__target-price-mini-cards"
          *ngIf="targetPriceWidget() as targetPriceWidget"
          [widget]="targetPriceWidget">
        </wt-wtk-widget-wrapper>
      </div>
    </wt-analytics-window>

    <wt-analytics-window [title]="'Recommendations'" [width]="column2Width" [height]="215">
      <div class="wt-company-overview__fundamental-ratios">
        <wt-wtk-widget-wrapper class="wt-company-overview__fundamental-ratios-mini-cards"
          *ngIf="recommendationsWidget() as recommendationsWidget"
          [widget]="recommendationsWidget">
        </wt-wtk-widget-wrapper>
      </div>
    </wt-analytics-window>
  </div>
</div>