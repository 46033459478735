import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-company-compare',
  templateUrl: './company-compare.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyCompareComponent {


}


