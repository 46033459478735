import type { AnalyticsMinimumMarketCapFilter } from '@infront/wtk/widgets/analytics-sector-information';

export const globalCompanySource: 'INFRONT' | 'MORNINGSTAR' = 'MORNINGSTAR';

// Company description
export const globalCompanyDescriptionSource: 'INFRONT' | 'MORNINGSTAR' | 'STANDARD_AND_POORS' = 'MORNINGSTAR';

// Top Shareholders, Top Officers, Board Members
export const globalCompanyDataSource: 'INFRONT' | 'MORNINGSTAR' | 'STANDARD_AND_POORS' = 'STANDARD_AND_POORS';

export const globalFundamentalSource: 'INFRONT' | 'MORNINGSTAR' = 'MORNINGSTAR';
export const globalKeyfigureSource: 'INFRONT' | 'MORNINGSTAR' = 'MORNINGSTAR';
export const globalEstimateSource: 'INFRONT' | 'STANDARD_AND_POORS' = 'STANDARD_AND_POORS';

export const globalMinimumMarketCap: AnalyticsMinimumMarketCapFilter = Object.freeze({ currency: 'EUR', value: 5_000_000 });