import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import type { Infront } from '@infront/sdk';
import { AnalyticsKeyFiguresWidget, AnalyticsKeyFiguresWidgetOptions } from '@infront/wtk/widgets/analytics-key-figures';
import { injectParams } from '../../shared/util';
import { createWidget } from '../../shared/wtk-widget-wrapper/wtk-widget-wrapper.model';
import { CompanyOverviewService } from '../overview/company-overview.service';
import { globalEstimateSource, globalFundamentalSource, globalKeyfigureSource } from '../../shared/models/settings';
@Component({
  selector: 'app-company-keyfigures',
  templateUrl: './company-keyfigures.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CompanyOverviewService]
})
export class CompanyKeyfiguresComponent {

  readonly isin = injectParams('isin');

  readonly keyFiguresWidget = computed(() => {
    return createWidget(AnalyticsKeyFiguresWidget, AnalyticsKeyFiguresWidgetOptions,
      {
        instrument: { isin: this.isin() } as Infront.Instrument,
        fundamentalSource: globalFundamentalSource,
        ltmSource: globalKeyfigureSource,
        estimateSource: globalEstimateSource,
        estimatePeriodCount: 3,
      });
  });
}
