import type { Infront } from '@infront/sdk';
import type { Constructor } from '@infront/webtrader-app/app/util/types';
import type { InfrontWidget, UI, WidgetOptions } from '@infront/wtk';
import type { SvelteWidgetBase, SvelteWidgetOptions } from '@infront/wtk/utils/svelte';


export type WTKWidget = InfrontWidget;
export type WTKWidgetOptions = WidgetOptions | SvelteWidgetOptions;

// Helper
export type WTKWidgetOptionsByWidget<Widget extends WTKWidget> = Widget extends SvelteWidgetBase ? Widget['options'] : WTKWidgetOptions;

export type WTKWidgetConstructorWithoutModel<
  Widget extends WTKWidget = WTKWidget,
  Options extends WTKWidgetOptionsByWidget<Widget> = WTKWidgetOptionsByWidget<Widget>
> = (
    Constructor<
      Widget,
      [HTMLElement, UI, Options]
    >
  );

export type WTKWidgetConstructorWithModel<
  Widget extends WTKWidget = WTKWidget,
  Options extends WTKWidgetOptionsByWidget<Widget> = WTKWidgetOptionsByWidget<Widget>
> = (
    Constructor<
      Widget,
      [HTMLElement, Infront.Model, UI, Options]
    >
  );

export type WTKWidgetConstructor<
  Widget extends WTKWidget = WTKWidget,
  Options extends WTKWidgetOptionsByWidget<Widget> = WTKWidgetOptionsByWidget<Widget>
> =
  WTKWidgetConstructorWithoutModel<Widget, Options> | WTKWidgetConstructorWithModel<Widget, Options>;

// options are unfortunately private, maybe todo for Toolkit?
// export type WTKWidgetConstructorWithOptions<Widget extends WTKWidget = WTKWidget> = { widgetConstructor: WTKWidgetConstructor<Widget>; widgetOptions: Widget['options']; };
export type WTKWidgetConstructorWithOptions<
  Widget extends WTKWidget = WTKWidget,
  Options extends WTKWidgetOptionsByWidget<Widget> = WTKWidgetOptionsByWidget<Widget>
> = { widgetConstructor: WTKWidgetConstructor<Widget, Options>, options: Options | undefined };

export const WTK_CSS_ENTRY_CLASS = 'infront-toolkit';

export const DEFAULT_WTK_WIDGET_OPTIONS_DEBOUNCE_TIME = 100; // miliseconds

// utility function to create a widget and options with less boilerplate
export function createWidget<
  Widget extends WTKWidget,
  Options extends WTKWidgetOptionsByWidget<Widget> = WTKWidgetOptionsByWidget<Widget>
>(
  widgetConstructor: WTKWidgetConstructorWithOptions<Widget, Options>['widgetConstructor'],
  options: (new (opts?: Partial<Options>) => Options) | Options,
  userOptions?: Partial<Options>,
): WTKWidgetConstructorWithOptions<Widget, Options> {
  if (typeof options === 'function') {
    options = new options(userOptions);
    // options = {
    //   ...new options(),
    //   ...userOptions,
    // };
  }
  return { widgetConstructor, options };
}
