import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, type OnDestroy, type OnInit, Output, inject } from '@angular/core';
import { LogService } from '@vwd/ngx-logging';
import { doOnFirstEmit } from '@infront/webtrader-app/app/util/rxjs';
import { DEFAULT_WTK_WIDGET_OPTIONS_DEBOUNCE_TIME, type WTKWidget, type WTKWidgetConstructorWithModel, type WTKWidgetConstructorWithOptions, type WTKWidgetConstructorWithoutModel, WTK_CSS_ENTRY_CLASS, type WTKWidgetOptionsByWidget } from './wtk-widget-wrapper.model';
import { SvelteWidgetBase } from '@infront/wtk/utils/svelte';
import type { UI, WidgetState } from '@infront/wtk';

import { type Observable, ReplaySubject, Subject, combineLatest, debounceTime, skip, takeUntil, tap } from 'rxjs';
import { ToolkitService } from '../../services/toolkit.service';

@Component({
  selector: 'wt-wtk-widget-wrapper',
  templateUrl: './wtk-widget-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    'class': WTK_CSS_ENTRY_CLASS
  }
})
export class WTKWidgetWrapperComponent<
  Widget extends WTKWidget,
  Options extends WTKWidgetOptionsByWidget<Widget> = WTKWidgetOptionsByWidget<Widget>
> implements OnInit, OnDestroy {
  private readonly targetElement = inject(ElementRef) as ElementRef<HTMLElement>;
  private readonly toolkitService = inject(ToolkitService);

  @Output() widgetRefChange = new EventEmitter<Widget>();
  @Output() widgetStateChange = new EventEmitter<WidgetState>();

  private _widget!: WTKWidgetConstructorWithOptions<Widget, Options>;
  @Input() set widget(widget: WTKWidgetConstructorWithOptions<Widget, Options>) {
    this._widget = widget;
    this.optionsChangeAction.next();
  }
  get widget(): WTKWidgetConstructorWithOptions<Widget, Options> {
    return this._widget;
  }

  @Input() optionsDebounceTime: number = DEFAULT_WTK_WIDGET_OPTIONS_DEBOUNCE_TIME; // Default 100ms

  private readonly optionsChangeAction = new ReplaySubject<void>(1);

  /** Bind observables that when on emit, should reinit the widget */
  private _reinitObservable$!: Observable<unknown>;
  @Input() set reinitObservable$(obs$: Observable<unknown>) {
    this._reinitObservable$ = obs$.pipe(
      debounceTime((this.optionsDebounceTime)),
      tap(() => this.reInit()),
    );
  }
  get reinitObservable$(): Observable<unknown> {
    return this._reinitObservable$;
  }

  widgetRef: Widget | undefined;
  private ui: UI | undefined;

  private readonly ngUnsubscribe = new Subject<void>();

  private readonly logService: LogService = inject(LogService);
  private readonly logger = this.logService.openLogger('analytics/wtk-widget-wrapper');

  constructor() {
    if (window.Highcharts == undefined) {
      this.logger.error('Highcharts is not available!');
    }
  }

  ngOnInit(): void {
    combineLatest([
      this.optionsChangeAction,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment
      this.toolkitService.infrontUI$.pipe(tap((ui) => this.ui = ui))
    ]).pipe(
      doOnFirstEmit(() => this.reInit()), // construct widget immediately
      skip(1),
      debounceTime((this.optionsDebounceTime)),
      tap(() => {
        this.reInit();
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.optionsChangeAction.complete();
    this.widgetRefChange.emit(undefined);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.widgetRef?.destroy();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  reInit(): void {
    // To replace with this.widgetRef.modify() when fixed for all widgets/components
    if (this.widgetRef instanceof SvelteWidgetBase && this.widget.options) {
      // @TODO TEST
      this.widgetRef.modify(this.widget.options);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      this.widgetRef?.destroy();
      const widgetConstructor = this.widget?.widgetConstructor;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const options = this.widget?.options;

      if (this.targetElement && this.ui && widgetConstructor && options) {
        // @TODO fix eslint below!
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
        (options as any).widgetStateCallback = (state: WidgetState): void => this.widgetStateChange.emit(state);
        if (widgetConstructor.length === 3) {
          this.widgetRef = new (widgetConstructor as WTKWidgetConstructorWithoutModel<Widget>)(this.targetElement.nativeElement, this.ui, options);
          this.widgetRefChange.emit(this.widgetRef);
        } else if (widgetConstructor.length === 4) {
          this.widgetRef = new (widgetConstructor as WTKWidgetConstructorWithModel<Widget>)(this.targetElement.nativeElement, this.ui.getModel(), this.ui, options);
          this.widgetRefChange.emit(this.widgetRef);
        }
      }
    }
  }
}
