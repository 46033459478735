import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Infront } from '@infront/sdk';
import { MFE_PREFS_TOKEN, initHost, initMFE, provideNgServices } from '@vwd/microfrontend-core';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import './i18n-registrations';

initMFE({
  basePath: environment.gateway + '/lib/micro-frontend/',
});

Infront.LibraryConstants.kBuildType = 'release';

const queryParams = new URLSearchParams(window.location.search);
const token = queryParams.get('token') ?? undefined; // token is used for login though an externally supplied keycloak token
const tkn = queryParams.get('tkn') ?? undefined; // tkn is used for backwards compatibility with six users

function preFetchToken(): Promise<string> {
  const body = new URLSearchParams();
  body.set('client_id', 'web-trader-client');
  body.set('username', 'sixserveruser');
  body.set('password', 'InfrontServ190131!');
  body.set('grant_type', 'password');

  const realm = typeof environment.realm === 'string' ? environment.realm : environment.realm?.default;
  return fetch(`${environment.keycloakUrl}/realms/${realm}/protocol/openid-connect/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: body.toString(),
  })
    .then(response => {
      if (!response.ok) {
        throw new Error(`Token fetch failed: ${response.statusText}`);
      }
      return response.json();
    })
    .then((data: { access_token: string }) => data.access_token)
    .catch(err => {
      console.error('Error fetching token:', err);
      throw err;
    });
}


function bootstrapAppWithToken(token?: string) {
  initHost({
    name: 'web-analytics-client',
    auth: {
      localeSupport: 'split-iso',
      loginRequired: true,
      realm: environment.realm,
      allowRealmViaURL: false,
      idmURL: environment.keycloakUrl,
      httpAddBearerFor: ['/api/*', '*.infrontfinance.com', '*.infrontservices.com', 'https://webclientservices.azurewebsites.net/*'],
      tokenStorage: 'localStorage',
      token: token,
      keycloakInitOptions: {
        useNonce: true,
        enableLogging: false,
        silentCheckSsoRedirectUri: '/assets/silent-check-sso.html',
      },
    },
    deps: [MFE_PREFS_TOKEN],
    lang: ['en-GB']
  }).then(host => {
    platformBrowserDynamic(provideNgServices(host))
      .bootstrapModule(AppModule)
      .catch((err: unknown) => console.error('Error bootstrapping the app:', err));
  }).catch((err: unknown) => console.error('Error initializing host:', err));
}

if (tkn) { // six users use tkn, add more determinants for id of six users later
  preFetchToken().then(bootstrapAppWithToken).catch(err => {
    console.error('App failed to start:', err);
  });
} else {
  bootstrapAppWithToken(token);
}
