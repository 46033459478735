import { Component, inject, type OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

@Component({
  standalone: true,
  template: '@if(invalid) { Invalid IPT call. }',
  imports: [RouterModule],
})
export class IptRedirectsComponent implements OnInit {
  router = inject(Router);
  route = inject(ActivatedRoute).snapshot;
  invalid = false;

  ngOnInit(): void {
    const { view, isin } = this.route.queryParams as Record<string, string>;

    if ([
      'overview',
      'estimates',
      'keyfigures',
      'sector',
      'financial-statements',
      'esg',
    ].includes(view) && isin) {
      void this.router.navigate(['/company', isin, view], {
        replaceUrl: true,
        // eslint-disable-next-line no-null/no-null, no-restricted-syntax
        queryParams: null,
      });
    } else {
      this.invalid = true;
    }
  }
}
