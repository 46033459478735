import { ChangeDetectionStrategy, Component, effect, inject } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { svgIcons } from '@infront/webtrader-app/auto/svgConstant';
import { ThemeService } from './services/theme.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  title = 'analytics-mini-site';

  private iconRegistry = inject(MatIconRegistry);
  private sanitizer = inject(DomSanitizer);

  themeService = inject(ThemeService);

  constructor() {

    effect(() => {
      const currentTheme = this.themeService.theme();
      for (const theme of this.themeService.supportedThemes) {
        document.body.classList.toggle(`wt-theme--${theme}`, currentTheme === theme);
      }
    });

    svgIcons.forEach((svgIcon) => {
      // Content of `svgIcons` is created by script `svgToJsConstants.script.js`
      // which is run manually by developers to create TS code
      // which is then checked during review process.
      this.iconRegistry.addSvgIcon(
        svgIcon.name,
        // see comment above, we consider content of `svgIcons` to be safe against XSS and therefore allow bypassing Angular security!
        this.sanitizer.bypassSecurityTrustResourceUrl(svgIcon.path)); // NOSONAR
    });
  }
}
