import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { InfrontSDK } from '@infront/sdk';
import { map, NEVER, switchMap } from 'rxjs';
import { SdkService } from '../../services/sdk.service';
import { typedFields } from '../util';

const FIELDS = [InfrontSDK.SymbolField.Ticker, InfrontSDK.SymbolField.FeedDesc, InfrontSDK.SymbolField.YesterdayClose, InfrontSDK.SymbolField.Currency, InfrontSDK.SymbolField.PerformanceYesterdayPct, InfrontSDK.SymbolField.KeyfigureMarketCapEur];

@Component({
  selector: 'wt-instrument-info',
  templateUrl: './instrument-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstrumentInfoComponent {
  private readonly sdkService: SdkService = inject(SdkService);

  isin = input<string>();
  marketCapEur = input<number>();

  private readonly values$ = toObservable(this.isin).pipe(switchMap(isin => {
    if (!isin) {
      return NEVER;
    }
    const opts: Partial<InfrontSDK.SymbolDataOptions> = {
      id: { isin },
      fields: FIELDS,
    };
    return this.sdkService.getObject$(InfrontSDK.symbolData, opts).pipe(
      map(symbol =>
        typedFields(FIELDS, symbol)
      ),
      map(result => {
        if (result.FeedDesc) {
          result.FeedDesc = result.FeedDesc.split(',')[0];
        }
        return result;
      }));
  }));

  readonly values = toSignal(this.values$);
}
