import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

const splashFadeAnimationTime = 300;

@Component({
  selector: 'wt-main-layout',
  templateUrl: './main-layout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('showSplash', [
      state('true', style({ opacity: '1' })),
      state('false', style({ opacity: '0' })),
      transition('0 <=> 1', animate(`${splashFadeAnimationTime}ms ease`)),
    ]),
  ],
})
export class MainLayoutComponent {
  readonly dialog = inject(MatDialog);

  showSplash = true;
  isSplash = true;

  constructor() {
    // toolkit init here?
    this.showSplash = false;
    this.isSplash = false;
  }
}
