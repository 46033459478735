import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'wt-analytics-tabs',
  templateUrl: './analytics-tabs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnalyticsTabsComponent {

  @Input() set tabStyle(value: undefined | 'chart') {
    this.classSuffix = value === 'chart' ? '-chart' : '';
  }
  @Input() tabs: string[] = [];
  @Output() readonly selectedTabChange = new EventEmitter<number>();
  selectedTabIndex = 0;
  classSuffix = '';

  selectTab(index: number): void {
    this.selectedTabIndex = index;
    this.selectedTabChange.emit(index);
  }
}
