<div class="wt-company-sector">
  <div class="wt-tabs-content">
    <div class="wt-tabs-content__col">
      <wt-analytics-window [title]="'COMPANY.SECTOR.SIMILAR_MARKET_CAP' | translate" [width]="column1Width" [height]="970">
        <div class="wt-analytics-window__sector wt-company-sector__window-header">

          <div class="wt-company-sector__window-header-selector">
            <mat-icon class="material-icons-outlined" fontIcon="factory"
              (click)="similarSectorsSubSectorSelectWidgetRef()?.toggleDropDown()"></mat-icon>
            <wt-wtk-widget-wrapper class="wt-company-sector__window-header-item" [widget]="similarSectorsSubSectorSelectWidget()" (widgetRefChange)="similarSectorsSubSectorSelectWidgetRef.set($event)"></wt-wtk-widget-wrapper>
          </div>

          <div class="wt-company-sector__window-header-selector">
            <mat-icon class="material-icons-outlined" fontIcon="flag"
              (click)="similarSectorsRegionSelectWidgetRef()?.toggleDropDown()"></mat-icon>
            <wt-wtk-widget-wrapper class="wt-company-sector__window-header-item" [widget]="similarSectorsRegionSelectWidget()" (widgetRefChange)="similarSectorsRegionSelectWidgetRef.set($event)"></wt-wtk-widget-wrapper>
          </div>

          <div class="wt-button__group wt-company-sector__window-header-item">
            <button *ngFor="let currency of currencies"
              type="button"
              [ngClass]="{ 'wt-button__group-item--selected': similarSectorsWidgetRef()?.options?.currency === currency }"
              (click)="similarSectorsWidgetRef()?.modify({ currency })"
              class="wt-button wt-button__group-item">
              {{ currency }}
            </button>
          </div>

        </div>

        <wt-wtk-widget-wrapper  *ngIf="similarSectorsWidget() as similarSectorsWidget" [widget]="similarSectorsWidget"
        (widgetRefChange)="similarSectorsWidgetRef.set($event)">
        </wt-wtk-widget-wrapper>
      </wt-analytics-window>
    </div>

    <div class="wt-tabs-content__col">
      <wt-analytics-window [title]="'COMPANY.SECTOR.BIGGEST_MARKET_CAP' | translate" [width]="column1Width" [height]="970">
        <div class="wt-analytics-window__sector wt-company-sector__window-header">

          <div class="wt-company-sector__window-header-selector">
            <mat-icon class="material-icons-outlined" fontIcon="factory"
              (click)="biggestSectorsSubSectorSelectWidgetRef()?.toggleDropDown()"></mat-icon>
            <wt-wtk-widget-wrapper class="wt-company-sector__window-header-item" [widget]="biggestSectorsSubSectorSelectWidget()" (widgetRefChange)="biggestSectorsSubSectorSelectWidgetRef.set($event)"></wt-wtk-widget-wrapper>
          </div>

          <div class="wt-company-sector__window-header-selector">
            <mat-icon class="material-icons-outlined" fontIcon="flag"
              (click)="biggestSectorsRegionSelectWidgetRef()?.toggleDropDown()"></mat-icon>
            <wt-wtk-widget-wrapper class="wt-company-sector__window-header-item" *ngIf="biggestSectorsRegionSelectWidget() as biggestSectorsRegionSelectWidget" [widget]="biggestSectorsRegionSelectWidget" (widgetRefChange)="biggestSectorsRegionSelectWidgetRef.set($event)"></wt-wtk-widget-wrapper>
          </div>

          <div class="wt-button__group wt-company-sector__window-header-item">
            <button *ngFor="let currency of currencies"
              type="button"
              [ngClass]="{ 'wt-button__group-item--selected': biggestSectorsWidgetRef()?.options?.currency === currency }"
              (click)="biggestSectorsWidgetRef()?.modify({ currency })"
              class="wt-button wt-button__group-item">
              {{ currency }}
            </button>
          </div>

        </div>

        <wt-wtk-widget-wrapper *ngIf="biggestSectorsWidget() as biggestSectorsWidget" [widget]="biggestSectorsWidget"
        (widgetRefChange)="biggestSectorsWidgetRef.set($event)">
        </wt-wtk-widget-wrapper>
      </wt-analytics-window>
    </div>

  </div>
</div>
