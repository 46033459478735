
<div class="wt-instrument-info"
  [attr.wt-metadata-source-1]="'equity/companies?isin=' + isin() + '&fields=company_basic.description_'"
  [attr.wt-metadata-source-2]="'equity/listings?isin=' + isin() + '&preferred_trading_venue_strategy_code=PRIMARY_TRADING_VENUE&fields=listing.common.ids_ticker%2Clisting.common.ids_feed_number'"
>
    <ng-container *ngIf="values() && isin()">
      <div class="wt-instrument-info__key-value">
        <div class="wt-instrument-info__key-value__key">{{ 'FIELD.TICKER' | translate }}/{{ 'FIELD.FEED_DESC' | translate }}</div>
        <div class="wt-instrument-info__key-value__value">{{ values()?.Ticker }}/{{ values()?.FeedDesc }}</div>
      </div>
      <div class="wt-instrument-info__key-value">
        <div class="wt-instrument-info__key-value__key">{{ 'FIELD.YESTERDAY_CLOSE' | translate }}</div>
        <div class="wt-instrument-info__key-value__value">{{ values()?.YesterdayClose }} {{ values()?.Currency }} <span [ngClass]="values()?.PerformanceYesterdayPct | classGetter: 'upDownColor'">{{ values()?.PerformanceYesterdayPct | formatGetter: 'twoDecimalsPercent' }}</span></div>
      </div>
      <div class="wt-instrument-info__key-value">
        <div class="wt-instrument-info__key-value__key">{{ 'FIELD.KEY_FIGURE_MARKET_CAP_EUR' | translate }}</div>
        <div class="wt-instrument-info__key-value__value">
          <wt-big-number [value]="$any(marketCapEur())" [decimals]="2"></wt-big-number>
      </div>
    </div>
    </ng-container>

</div>
