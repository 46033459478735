import { inject, Injectable, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  readonly theme = signal('dark');
  readonly supportedThemes: readonly string[] = ['dark', 'light'];

  private readonly route = inject(ActivatedRoute);

  constructor() {
    this.route.queryParams.subscribe((queryParams) => {
      const { Infi_theme, infi_theme } = queryParams as Record<string, string>;

      const theme = Infi_theme ?? infi_theme;
      if (this.supportedThemes.includes(theme)) {
        this.theme.set(theme);
      }
    });
  }
}
