<div class="wt-analytics-search">
  <input
    class="wt-analytics-search__input"
    [value]="searchInputValue()"
    (input)="onInput($event)"
    [placeholder]="placeholder || ('SEARCH.INPUT_PLACEHOLDER' | translate)"
    (focus)="activateDropdown()"
    (click)="activateDropdown()"
    autocomplete="off"
    type="search"
    id="search"
    #searchElm />
</div>

<div (clickOutside)="deactivateDropdown()" [ignoreElements]="[searchElm]" *ngIf="isDropdownActive()">
  <div class="wt-analytics-search__dropdown" *ngIf="isDropdownActive()">
    <div class="wt-analytics-search__item" *ngFor="let item of searchContent()" (click)="itemSelected(item)">
      <ng-container *ngIf="item">
        <div><img [src]="item.flagPath" class="wt-analytics-search__item-flag"></div>
        <div>{{ item['company_basic.name'] || item['issuer.common.name'] || item['listing.common.name'] }}</div>
      </ng-container>
    </div>
  </div>
</div>
