import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'wt-copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopyToClipboardComponent {
  @Input() title?: string;
  @Input() translateTitle?: boolean;
}
