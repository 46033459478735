import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import type { Infront, InfrontSDK } from '@infront/sdk';
import { AnalyticsEstimatesBarWidget, AnalyticsEstimatesBarWidgetOptions } from '@infront/wtk/widgets/analytics-estimates-bar';
import { AnalyticsHistoricalRecommendationsWidget, AnalyticsHistoricalRecommendationsWidgetOptions } from '@infront/wtk/widgets/analytics-historical-recommendations';
import { AnalyticsKeyFiguresWidget, AnalyticsKeyFiguresWidgetOptions } from '@infront/wtk/widgets/analytics-key-figures';
import { AnalyticsTargetPriceWidget, AnalyticsTargetPriceWidgetOptions } from '@infront/wtk/widgets/analytics-target-price';
import { HeaderService } from '../../layout/header/header.service';
import { injectParams } from '../../shared/util';
import { createWidget } from '../../shared/wtk-widget-wrapper/wtk-widget-wrapper.model';
import { globalEstimateSource, globalFundamentalSource, globalKeyfigureSource } from '../../shared/models/settings';


@Component({
  selector: 'app-company-estimates',
  templateUrl: './company-estimates.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyEstimatesComponent {
  readonly headerService = inject(HeaderService);
  readonly isin = injectParams('isin');
  readonly column1Width = 600;
  readonly column2Width = 400;

  readonly estimatesBarWidget = computed(() => {
    return createWidget(AnalyticsEstimatesBarWidget, AnalyticsEstimatesBarWidgetOptions, {
      instrument: { isin: this.isin() } as Infront.Instrument, showExtraInformationIcon: true, onShowExtraInformationClicked: (symbol: InfrontSDK.SymbolId) => {
        void this.headerService.openOverlay('analysts', { isin: symbol.isin! });
      },
    });
  });

  readonly targetPriceWidget = computed(() => {
    return createWidget(AnalyticsTargetPriceWidget, AnalyticsTargetPriceWidgetOptions, { instrument: { isin: this.isin() } as Infront.Instrument });
  });

  readonly recommendationsWidget = computed(() => {
    return createWidget(AnalyticsHistoricalRecommendationsWidget, AnalyticsHistoricalRecommendationsWidgetOptions, { instrument: { isin: this.isin() } as Infront.Instrument });
  });

  readonly keyFiguresWidget = computed(() => {
    return createWidget(AnalyticsKeyFiguresWidget, AnalyticsKeyFiguresWidgetOptions,
      {
        instrument: { isin: this.isin() } as Infront.Instrument,
        fundamentalSource: globalFundamentalSource,
        ltmSource: globalKeyfigureSource,
        estimateSource: globalEstimateSource,
        fundamentalPeriodCount: 1,
        estimatePeriodCount: 3,
      });
  });
}
