import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';
import { ClickOutsideDirective } from '@infront/webtrader-app/app/directives/click-outside.directive';
import { SearchComponent } from './search.component';

@NgModule({
  exports: [
    SearchComponent,
  ],
  declarations: [
    SearchComponent,
    ClickOutsideDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,

    MatIconModule,

    I18nTranslateModule.forResourceSet({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en']
    }),
  ]
})
export class SearchModule { }
