import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import type { Infront } from '@infront/sdk';
import { AnalyticsGprvWidget, AnalyticsGprvWidgetOptions } from '@infront/wtk/widgets/analytics-gprv';
import { injectParams } from '../../shared/util';
import { createWidget } from '../../shared/wtk-widget-wrapper/wtk-widget-wrapper.model';
import { fullWidth } from '../company.model';
import { globalKeyfigureSource } from '../../shared/models/settings';

@Component({
  selector: 'app-company-gprv',
  templateUrl: './company-gprv.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CompanyGprvComponent {

  width = fullWidth;

  readonly isin = injectParams('isin');

  readonly gprvWidget = computed(() => createWidget(
    AnalyticsGprvWidget,
    AnalyticsGprvWidgetOptions,
    {
      instrument: { isin: this.isin() } as Infront.Instrument,
      showTable: true,
      ltmSource: globalKeyfigureSource,
    }
  ));
}
