import { UtilService } from '../../services/util.service';

import { Pipe, type PipeTransform, inject } from '@angular/core';

@Pipe({
  name: 'formatGetter'
})
export class FormatGetterPipe implements PipeTransform {
  private readonly utilService: UtilService = inject(UtilService);

  transform(value: number | undefined, formatGetter: keyof UtilService): string {
    const formatMethod = this.utilService[formatGetter];
    const formattedValue = formatMethod(value) as string;
    return formattedValue;
  }
}
