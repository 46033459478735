import { ChangeDetectionStrategy, Component, inject, type OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit {
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);

  ngOnInit(): void {
    this.route.queryParams.subscribe(inParams => {
      const params = inParams as unknown as { isin: string; page: string };
      const isin = params['isin'];
      const page = params['page'];

      const pageMap: { [key: string]: string } = {
        company: `company/${isin}`,
        fundamentals: `company/${isin}/financial-statements`,
        estimates: `company/${isin}/estimates`,
        charts: `company/${isin}/charts`,
        esg: `company/${isin}/esg`,
        compare: `company/${isin}/comapare`,
        overview: `company/${isin}/overview`
      };
      if (!isin) {
        void this.router.navigate(['']);
        return;
      }
      if (!pageMap[page]) {
        const url = `${pageMap['overview']}`;
        void this.router.navigate([url]);
        return;
      }
      const url = `${pageMap[page]}`;
      void this.router.navigate([url]);
    });
  }
}
