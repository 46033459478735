import { Injectable, inject } from '@angular/core';
import { Infront, InfrontUtil } from '@infront/sdk';

import { KeycloakAuthService } from '@vwd/keycloak-auth-angular';
import { LogService } from '@vwd/ngx-logging';
import { Observable, Subject, type Subscriber, combineLatest, from, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';

import { NumberSymbol, getLocaleNumberSymbol } from '@angular/common';


import { type InfrontUIOptions, UI } from '@infront/wtk';
import { environment } from '../../environments/environment';

import { LOCALE_ID$ } from '../../../../webtrader-app/src/app/util/locale';

export const ToolkitThrottlingTime = 500;

const TEMPORARY_CREDENTIALS: Partial<InfrontUIOptions> = {
  user_id: '',
  // REVIEWERS take extra care: do never allow real password in the following line!
  password: '', // NOSONAR
};
const TOOLKIT_OPTIONS: Partial<InfrontUIOptions> = {
  streaming: true,
  language: 'en',
  secureConnection: Infront.ConnectionSecurity.Require,
  enableLoginDialog: true,
  useDefaultStateStorage: true,
  throttling: ToolkitThrottlingTime,
  // baseCurrency: 'EUR', // does not exist on InfrontUIOptions (?)
};

@Injectable({
  providedIn: 'root',
})
export class ToolkitService {
  // In case of Keycloak token not working as Toolkit login, this option can be used as failover-solution for local development.
  // - useKeycloakToken = true -> toolkitInitWithToken$ - must be used in production!
  // - useKeycloakToken = false -> toolkitInitWithCredentials$ - only to be used in local development!
  // When set to false, username and password need to be provided in the TEMPORARY_CREDENTIALS const.
  private readonly useKeycloakToken = true;
  private readonly localeId$ = inject(LOCALE_ID$);
  private readonly logger = inject(LogService).openLogger('services/toolkit');
  private readonly keycloakService = inject(KeycloakAuthService);

  private readonly toolkitInitWithCredentials$ = new Observable((obs: Subscriber<UI>) =>
    this.initInfrontUI(
      new UI({
        ...TOOLKIT_OPTIONS,
        ...TEMPORARY_CREDENTIALS,
      } as InfrontUIOptions),
      obs
    )
  );

  private readonly toolkitInitWithToken$ = of(undefined).pipe(
    switchMap(() =>
      combineLatest([from(this.keycloakService.getToken())]).pipe(
        switchMap(
          ([token]) =>
            new Observable((obs: Subscriber<UI>) => {
              const features = { coredata_url: new URL('/api/cdapi/', environment.gateway).href, dochub_documentsUrl: new URL('/api/document-hub/', environment.gateway).href };

              this.initInfrontUI(
                new UI({
                  ...TOOLKIT_OPTIONS,
                  signed_token: token,
                  useDefaultStateStorage: true, // Use default storage if no connection to remote server
                  features,
                } as InfrontUIOptions),
                obs
              );
            })
        )
      )
    )
  );

  readonly infrontUI$ = (this.useKeycloakToken ? this.toolkitInitWithToken$ : this.toolkitInitWithCredentials$).pipe(shareReplay(1));

  readonly availableFeeds$ = this.infrontUI$.pipe(map((infrontUI: UI) => infrontUI?.getModel()?.login?.feeds), shareReplay(1));

  private readonly disconnectAction$ = new Subject<void>();
  disconnect$ = this.disconnectAction$.asObservable();

  constructor() {
    this.logger.info('InfrontUtil.formatSettings:', InfrontUtil.formatSettings);

    this.localeId$.subscribe((locale) => {
      this.onLocaleChange(locale);
    });
  }

  private initInfrontUI(infrontUI: UI, obs: Subscriber<UI>): void {
    infrontUI.registerEventObserver('onReady', () => {
      if (environment.development && window) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        (window as any)['InfrontWT5Debug'] = Infront; // for development runtime debug of Infront namespace
      }
      obs.next(infrontUI);
    });

    infrontUI.registerEventObserver('onDisconnect', () => {
      this.disconnectAction$.next();
    });

    infrontUI.init();
  }

  private onLocaleChange(locale: string): void {
    InfrontUtil.formatSettings.useBrowserFormatting = false;
    InfrontUtil.formatSettings.thousandsSeparator = getLocaleNumberSymbol(locale, NumberSymbol.Group);
    InfrontUtil.formatSettings.decimalSeparator = getLocaleNumberSymbol(locale, NumberSymbol.Decimal);
  }
}
