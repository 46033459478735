import { inject, Injectable } from '@angular/core';
import { CountryFlagMap, type FlagSize } from '@infront/wtk/utils/country-flags';

import { UtilService } from './util.service';

const ASSET_PATH: Readonly<string> = 'assets/flags';
const IMAGE_FORMAT: Readonly<string> = 'png';
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const FALLBACK_FLAG: Readonly<string> = CountryFlagMap.GLOBAL;

@Injectable()
export class CountryFlagService {
  private readonly utilService: UtilService = inject(UtilService);

  getCountryFlagPath(country: string | number | undefined, size: FlagSize, allowFallbackFlag = true, fallbackFlag = FALLBACK_FLAG): string {
    const fileName: string | undefined = this.utilService.getCountryFileNameForFlag(country, allowFallbackFlag, fallbackFlag);
    if (!fileName) {
      return '';
    }
    const src = `${ASSET_PATH}/${size}/${fileName}.${IMAGE_FORMAT}`;
    return src;
  }
}
