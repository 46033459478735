import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { FlagAndNameComponent } from './flag-and-name.component';

@NgModule({
  declarations: [
    FlagAndNameComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
  ],
  providers: [],
  exports: [FlagAndNameComponent]
})
export class FlagAndNameModule { }
