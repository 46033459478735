<div class="wt-flex-row wt-flex-align-middle">
  <div class="wt-flex-column wt-flex-shrink wt-flex-collapse wt-header__logo">
    <div class="wt-header__logo-upper">Infront</div>
    <div class="wt-header__logo-lower">Analytics</div>
  </div>

  <div class="wt-flex-column wt-flex-shrink">
    <wt-app-search></wt-app-search>
  </div>

  <nav class="wt-flex-column wt-flex-shrink wt-header__addons">
    <ul class="wt-list-inline" *ngIf="{ name: headerService.activeOverlayName$ | async } as overlay">
      <li class="wt-header__addons-item">
        <button class="wt-button wt-button--primary wt-header__addons-button"
          [ngClass]="{ 'wt-header__addons-button--active': overlay.name === 'screener'}"
          type="button"
          (click)="headerService.openOverlay('screener')">
          <mat-icon class="material-icons-outlined" fontIcon="travel_explore"></mat-icon>
          {{ 'HEADER.ADDONS.SCREENER' | translate }}</button>
      </li>
      <li class="wt-header__addons-item">
        <button class="wt-button wt-button--primary wt-header__addons-button" type="button"
          [ngClass]="{ 'wt-header__addons-button--active': overlay.name === 'sectors'}"
          (click)="headerService.openOverlay('sectors')">
          <mat-icon class="material-icons-outlined" fontIcon="factory"></mat-icon>
          {{ 'HEADER.ADDONS.SECTORS' | translate }}</button>
      </li>
      <li class="wt-header__addons-item">
        <button class="wt-button wt-button--primary wt-header__addons-button" type="button"
          [ngClass]="{ 'wt-header__addons-button--active': overlay.name === 'watchlists'}"
          (click)="headerService.openOverlay('watchlists')">
          <mat-icon class="material-icons-outlined" fontIcon="visibility"></mat-icon>
          {{ 'HEADER.ADDONS.WATCHLISTS' | translate }}</button>
      </li>
    </ul>
  </nav>
  @if(development) {
  <nav class="wt-flex-column wt-flex-shrink wt-header__addons wt-header__theme-selector">
    <span class="wt-header__theme-selector-label">
      Theme:
    </span>
    <ul class="wt-list-inline">
      @for (themeItem of supportedThemes; track $index) {
      <li class="wt-header__addons-item">
        <button class="wt-button wt-header__addons-button"
          [class.wt-header__addons-button-selected]="theme() === themeItem"
          type="button" (click)="theme.set(themeItem)">{{themeItem}}</button>
      </li>
      }
    </ul>
  </nav>
  }
</div>

<ng-container *ngIf="navigate$ | async"></ng-container>