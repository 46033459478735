import { NgModule } from '@angular/core';
import { RouterModule, type Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { IptRedirectsComponent } from './ipt-redirects.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'company',
    loadChildren: () => import('./company/company.module').then(m => m.CompanyModule)
  },
  {
    path: 'ipt',
    component: IptRedirectsComponent,
  },
  // the following paths are intended to future redirects when we have corresponding modules and components
  {
    path: 'snapshot',
    redirectTo: ''
  },
  {
    path: 'fundamentals',
    redirectTo: ''
  },
  {
    path: 'factsheet',
    redirectTo: ''
  },
  {
    path: 'estimates',
    redirectTo: ''
  },
  {
    path: 'multiples',
    redirectTo: ''
  },
  {
    path: 'ratios',
    redirectTo: ''
  },
  {
    path: 'snapshot2',
    redirectTo: ''
  },
  // all others paths
  {
    path: '**',
    redirectTo: '', // Redirects any unknown routes to the home page
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always'
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
