import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { BigNumberModule } from '@infront/webtrader-app/app/shared/big-number/big-number.module';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';
import { AgGridModule } from 'ag-grid-angular';
//import { WatchlistItemMenuComponent } from '../../watchlists/watchlist-item-menu/watchlist-item-menu.component';
import { FlagAndNameModule } from '../../watchlists/flag-and-name/flag-and-name.module';
import { WatchlistItemMenuModule } from '../../watchlists/watchlist-item-menu/watchlist-item-menu.module';
import { AgGridWrapperComponent } from './ag-grid.wrapper.component';


@NgModule({
  declarations: [
    AgGridWrapperComponent,
  ],
  imports: [
    CommonModule,
    AgGridModule,
    MatIconModule,
    BigNumberModule,
    WatchlistItemMenuModule,
    FlagAndNameModule,
    MatMenuModule,
    I18nTranslateModule.forResourceSet({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en']
    }),

  ],
  exports: [AgGridWrapperComponent],
})
export class AgGridWrapperModule { }
