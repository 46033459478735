import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { AnalyticsFinancialStatementsWidget, AnalyticsFinancialStatementsWidgetOptions } from '@infront/wtk/widgets/analytics-financial-statements';
import { createWidget } from '../../shared/wtk-widget-wrapper/wtk-widget-wrapper.model';
import { CompanyOverviewService } from '../overview/company-overview.service';
@Component({
  selector: 'app-company-financial-statements',
  templateUrl: './company-financial-statements.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CompanyOverviewService]
})
export class CompanyFinancialStatementsComponent {

  private readonly companyOverviewService = inject(CompanyOverviewService);

  readonly fincancialStatementWidget = computed(() => createWidget(AnalyticsFinancialStatementsWidget, AnalyticsFinancialStatementsWidgetOptions, { instrument: this.companyOverviewService.instrument(), usePerfectScrollbar: false }));

}
