import { computed, inject, Injectable } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { Infront, InfrontSDK } from '@infront/sdk';
import { map, switchMap } from 'rxjs';
import { SdkService } from '../../services/sdk.service';
import { GetValuesFromFields, PeriodKPIFigures, SnapshotKPIFigures } from '../../shared/models/cdapi';
import { injectParams, typedFields } from '../../shared/util';
import { CdapiGetEquityCompaniesFields, CdapiGetEquityCompanyFundamentalsPeriodKPIsFields, CdapiGetEquityCompanyFundamentalsSnapshotKPIsFields } from './company-overview.model';
@Injectable()
export class CompanyOverviewService {
  private readonly sdkService: SdkService = inject(SdkService);
  readonly isin = injectParams('isin');

  // signals for complete cdapi responses (type mapped by which fields are sent in the options)

  readonly cdapiGetEquityCompanies = toSignal(this.sdkService.cdApiRequest$(InfrontSDK.CoreDataAPI.cdapiGetEquityCompanies,
    { isin: this.isin(), fields: CdapiGetEquityCompaniesFields }).pipe(
      map(items => GetValuesFromFields(items[0], CdapiGetEquityCompaniesFields)
      )));

  private readonly cdapiGetEquityCompanyFundamentalsPeriodKPIs = toSignal(
    this.sdkService.cdApiRequest$(InfrontSDK.CoreDataAPI.cdapiGetEquityCompanyFundamentals,
      { isin: this.isin(), fields: CdapiGetEquityCompanyFundamentalsPeriodKPIsFields, report_type: 'ANNUAL' as const }
    ).pipe(
      map(item => GetValuesFromFields(item[0], CdapiGetEquityCompanyFundamentalsPeriodKPIsFields))
    )
  );

  private readonly cdapiGetEquityCompanyFundamentalsSnapshotKPIs = toSignal(
    this.sdkService.cdApiRequest$(
      InfrontSDK.CoreDataAPI.cdapiGetEquityCompanyFundamentals,
      { isin: this.isin(), fields: CdapiGetEquityCompanyFundamentalsSnapshotKPIsFields, report_type: 'ANNUAL' as const }
    ).pipe(
      map(items => GetValuesFromFields(items[0], CdapiGetEquityCompanyFundamentalsSnapshotKPIsFields))
    )
  );

  // signals for drilled down parts of cdapi responses
  readonly dps = computed(() => {
    const figures = this.cdapiGetEquityCompanyFundamentalsPeriodKPIs()?.[PeriodKPIFigures];
    return figures?.find(figure => figure?.code === 'kpi_dps_ord');
  });

  readonly eps = computed(() => {
    const figures = this.cdapiGetEquityCompanyFundamentalsPeriodKPIs()?.[PeriodKPIFigures];
    return figures?.find(figure => figure?.code === 'kpi_calc_eps');
  });

  readonly salesPs = computed(() => {
    const figures = this.cdapiGetEquityCompanyFundamentalsPeriodKPIs()?.[PeriodKPIFigures];
    return figures?.find(figure => figure?.code === 'kpi_calc_sales_ps');
  });

  readonly bvps = computed(() => {
    const figures = this.cdapiGetEquityCompanyFundamentalsSnapshotKPIs()?.[SnapshotKPIFigures];
    return figures?.find(figure => figure?.code === 'kpi_calc_bvps');
  });

  // signal of all fields specified in one request from SDK SymbolData fields request
  readonly cdApiDataFromFields = toSignal(toObservable(this.isin).pipe(switchMap(isin => {
    const fields = [InfrontSDK.SymbolField.IdsTicker, InfrontSDK.SymbolField.IdsFeed];
    const opts: Partial<InfrontSDK.SymbolDataOptions> = {
      id: { isin },
      fields
    };
    return this.sdkService.getObject$(InfrontSDK.symbolData, opts).pipe(map(symbol =>
      typedFields(fields, symbol)
    ));
  })));

  // signal of instrument from cdApiDataFromFields feed and ticker
  readonly instrument = computed(() => {
    if (!this.cdApiDataFromFields()) {
      return undefined;
    }
    const { [InfrontSDK.SymbolField.IdsTicker]: ticker, [InfrontSDK.SymbolField.IdsFeed]: feed } = this.cdApiDataFromFields()!;
    return { ticker, feed } as Infront.Instrument;
  });

}

