<div class="wt-analytics-window" style="width: 1000px; height: 780px;">
  <div class="wt-analytics-window__header">
    Key Figures
  </div>

  <div class="wt-analytics-window__content">
    <div class="wt-keyfigures">
      <wt-wtk-widget-wrapper [widget]="keyFiguresWidget()"></wt-wtk-widget-wrapper>
    </div>
  </div>
</div>