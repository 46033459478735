import { ChangeDetectionStrategy, Component, computed, type ElementRef, EventEmitter, inject, Input, Output, signal, ViewChild } from '@angular/core';
import { CompanyService } from '../company/company.service';
import { CountryFlagService } from '../services/country-flag.service';
import { Isin } from '../shared/models/cdapi';
import type { UnwrapSignalItem } from '../shared/util';
import { ANALYTICS_SEARCH_HISTORY, SEARCH_HISTORY_LIMIT } from './search.model';
import { SearchService } from './search.service';

@Component({
  selector: 'wt-app-search',
  templateUrl: './search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SearchService, CountryFlagService]
})
export class SearchComponent {
  private readonly searchService = inject(SearchService);
  private readonly companyService = inject(CompanyService);

  @Output() selectedIsin = new EventEmitter<string>();
  @Input() placeholder = '';

  @ViewChild('searchElm', { 'static': false }) searchElm!: ElementRef<HTMLInputElement>;

  readonly searchInputValue = signal('');
  readonly isDropdownActive = signal(false);

  readonly searchResult = this.searchService.searchResult;

  readonly searchContent = computed(() => {
    if (!this.isDropdownActive()) {
      return [];
    }
    const results = this.searchResult();
    if (this.searchInputValue()?.length) {
      return results;
    }
    return this.getHistory();
  });

  onInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const searchString = inputElement.value;
    this.searchInputValue.set(searchString);
    this.searchService.search(searchString);
  }

  async itemSelected(item: (UnwrapSignalItem<typeof this.searchResult>) | undefined): Promise<void> {
    const isin = item?.[Isin];
    if (!isin) {
      return;
    }
    this.addToSearchHistory(item);
    this.selectedIsin.emit(isin);
    await this.companyService.onSelectedIsin(isin);
    this.isDropdownActive.set(false);
    this.searchInputValue.set('');
  }

  private getHistory(): (UnwrapSignalItem<typeof this.searchResult> | undefined)[] {
    const storedHistory = localStorage.getItem(ANALYTICS_SEARCH_HISTORY);
    if (storedHistory) {
      return JSON.parse(storedHistory) as (UnwrapSignalItem<typeof this.searchResult> | undefined)[];
    }
    return [];
  }

  private addToSearchHistory(item: UnwrapSignalItem<typeof this.searchResult>): void {
    const history = this.getHistory().filter(itm => itm?.[Isin] !== item?.[Isin]);
    history.unshift(item);
    if (history.length > SEARCH_HISTORY_LIMIT) {
      history.pop();
    }
    localStorage.setItem(ANALYTICS_SEARCH_HISTORY, JSON.stringify(history));
  }

  clearSearchInput(): void {
    this.searchElm.nativeElement.blur();
    this.searchInputValue.set('');
  }

  deactivateDropdown(): void {
    this.isDropdownActive.set(false);
  }

  activateDropdown(): void {
    this.isDropdownActive.set(true);
  }
}
