import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';
import { WatchlistItemMenuComponent } from './watchlist-item-menu.component';

@NgModule({
  declarations: [
    WatchlistItemMenuComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    I18nTranslateModule.forResourceSet({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en']
    }),
  ],
  providers: [],
  exports: [WatchlistItemMenuComponent]
})
export class WatchlistItemMenuModule { }
