import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Infront, InfrontSDK } from '@infront/sdk';
import { filterUndefined } from '@infront/webtrader-app/app/util/rxjs';
import { EsgBestCompaniesWidget, EsgBestCompaniesWidgetOptions } from '@infront/wtk/widgets/esg-best-companies';
import { EsgTableViewWidget, EsgTableViewWidgetOptions } from '@infront/wtk/widgets/esg-table-view';
import { EsgTopScoreWidget, EsgTopScoreWidgetOptions } from '@infront/wtk/widgets/esg-top-score';
import { combineLatest, map, switchMap } from 'rxjs';
import { CommonRequestsService } from '../../services/common-requests.service';
import { SdkService } from '../../services/sdk.service';
import { GicsIndustryGroupCode, GicsSectorCode, GicsSectorName } from '../../shared/models/cdapi';
import { injectParams } from '../../shared/util';
import { injectParams$, mergeObjectArrays } from '../../shared/util-rxjs';
import { createWidget } from '../../shared/wtk-widget-wrapper/wtk-widget-wrapper.model';
@Component({
  selector: 'app-company-esg',
  templateUrl: './company-esg.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyEsgComponent {
  readonly isin = injectParams('isin');
  readonly sdkService = inject(SdkService);
  readonly commonRequestsService = inject(CommonRequestsService);
  readonly column1Width = 600;
  readonly column2Width = 400;

  readonly vm = toSignal(injectParams$('isin').pipe(
    filterUndefined(),
    switchMap(isin => {
      const sectors$ = this.sdkService.cdapiMappedRequest$(InfrontSDK.CoreDataAPI.cdapiGetEquityClassificationGicsSectors,
        { isin, fields: [GicsSectorName, GicsSectorCode] });

      return combineLatest([sectors$, this.commonRequestsService.gicsIndustryGroups$(isin)]).pipe(
        mergeObjectArrays(),
        map(results => ({
          ...results,
          // change to esgSectorScoresWidget when widget accepts gicsSectorCode
          esgSectorScoresWidget: createWidget(EsgBestCompaniesWidget, EsgBestCompaniesWidgetOptions, { gicsSectorCode: results[GicsSectorCode] }),
          esgBestCompaniesWidget: createWidget(EsgBestCompaniesWidget, EsgBestCompaniesWidgetOptions, { gicsIndustryGroupCode: results[GicsIndustryGroupCode] }),
          esgTopScoreWidget: createWidget(EsgTopScoreWidget, EsgTopScoreWidgetOptions, { instrument: { isin } as Infront.Instrument }),
          esgTableViewWidget: createWidget(EsgTableViewWidget, EsgTableViewWidgetOptions, { instrument: { isin } as Infront.Instrument }),
        })));
    })));
}



