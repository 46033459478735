import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LogService } from '@vwd/ngx-logging';

import { DefaultChildRoute } from './company-routing.module';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private readonly router = inject(Router);
  private readonly logger = inject(LogService).openLogger('app/company');

  async onSelectedIsin(isin: string): Promise<void> {
    if (isin?.length !== 12) {
      this.logger.info('onSelectedIsin', 'invalid isin provided:', isin);
      return;
    }
    const fullUrl = this.router.url;
    const urlSegments = fullUrl.split('/');
    const currentChildRoute = urlSegments[urlSegments.length - 1];
    const targetPath = currentChildRoute && currentChildRoute !== 'company' ? currentChildRoute : DefaultChildRoute;
    await this.router.navigate([`/company/${isin}/${targetPath}`]);
  }

}
