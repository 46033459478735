import { inject, Injectable } from '@angular/core';

import { overlayDefaults, overlayDefs, type MatDataByOverlay, type OverlayDefn, type OverlayName } from './header.model';
import { MatDialog } from '@angular/material/dialog';
import { InfrontUtil } from '@infront/sdk';
import { ReplaySubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  private readonly dialog = inject(MatDialog);

  private readonly activeOverlayNameAction = new ReplaySubject<OverlayName | undefined>(1);
  readonly activeOverlayName$ = this.activeOverlayNameAction.asObservable();
  private overlaySubscription?: Subscription;


  async openOverlay<T extends OverlayName = OverlayName>(name: T, matData?: MatDataByOverlay[T]): Promise<void> {
    const overlay = InfrontUtil.deepCopy(overlayDefs[name]) as OverlayDefn | undefined;

    if (overlay == undefined) {
      return;
    }

    // @FIXME MatDialog doesn't accept opening modules but only components.
    // load the module first to ensure dependencies are resolved
    if (overlay.loadModule) {
      await overlay.loadModule();
    }

    const component = await overlay.loadComponent();

    overlay.config = Object.assign(InfrontUtil.deepCopy(overlayDefaults.config) as OverlayDefn['config'], overlay.config);

    if (matData !== undefined) {
      overlay.config.data = matData;
    }

    this.overlaySubscription?.unsubscribe();
    const openOverlay = this.dialog.open(component, overlay.config);
    this.overlaySubscription = openOverlay.afterClosed().subscribe({
      complete: () => {
        this.activeOverlayNameAction?.next(undefined);
        this.overlaySubscription = undefined;
      },
    });
    this.activeOverlayNameAction?.next(name);
  }

}
