import type { InfrontSDK } from '@infront/sdk';

// issuer fields

export const SectorName = 'issuer.common.sector.name';
export const IssuerSectorId = 'issuer_sector.id';
export const IssuerSectorName = 'issuer_sector.name';
export const IssuerSubSectorName = 'issuer_sub_sector.name';
export const IssuerSubSectorId = 'issuer_sub_sector.id';

export const SubSectorName = 'issuer.common.sub_sector.name';
export const SubSectorId = 'issuer.common.sub_sector.id';
export const IssuerName = 'issuer.common.name';
export const IssuerNameShort = 'issuer.common.name_short';
export const CountryCode = 'issuer.common.country_code';

export const MarketCapEur = 'issuer_keyfigure.equity.market_capitalization_eur';
export const PS = 'issuer_keyfigure.equity.price_sales_ratio';
export const PE = 'issuer_keyfigure.equity.price_earnings_ratio';
export const PB = 'issuer_keyfigure.equity.price_bookvalue_ratio';
export const EvEbitda = 'issuer_keyfigure.equity.enterprise_value_ebitda_ratio';
export const DivYield = 'issuer_keyfigure.equity.dividend_yield';

export const NetSales = 'issuer_keyfigure.equity.net_sales';
export const NetSalesGrowth = 'issuer_keyfigure.equity.growth_net_sales';
export const GrossProfit = 'issuer_keyfigure.equity.gross_profit';
export const GrossProfitMargin = 'issuer_keyfigure.equity.gross_profit_margin';
export const Ebit = 'issuer_keyfigure.equity.ebit';
export const EbitMargin = 'issuer_keyfigure.equity.ebitMargin';

// classification fields
export const GicsIndustryGroupName = 'gics_industry_group.name';
export const GicsIndustryGroupCode = 'gics_industry_group.code';

//
export const GicsSectorName = 'gics_sector.name';
export const GicsSectorCode = 'gics_sector.code';

// listings fields
export const Country = 'country.name';
export const Isin = 'instrument.common.isin';
export const ListingName = 'listing.common.name';

// instrument fields

export const InstrumentName = 'instrument.common.name';

// company fields

export const CompanyName = 'company_basic.name';
export const CompanyBasicCountryCode = 'company_basic.country_code';

// fundamentals sources // these are broader than other fields, we filter out fields by figure_codes from these in front end (backend also possible but more requests)
export const PeriodKPIFigures = 'fundamental_infront.taxonomies.period_kpi.figures';
export const SnapshotKPIFigures = 'fundamental_infront.taxonomies.snapshot_kpi.figures';
export const IncomeStatement = 'fundamental_infront.taxonomies.income_statement';


// Do not use FieldTypeMap anymore, all types can be derived from the fields, but old code using it has not been refactored
export interface FieldTypeMap {
  [MarketCapEur]: number;
  [SectorName]: string;
  [SubSectorName]: string;
  [PeriodKPIFigures]: InfrontSDK.CoreDataAPI.CdapiEquityCompanyFundamentalFigure[];
  [SnapshotKPIFigures]: InfrontSDK.CoreDataAPI.CdapiEquityCompanyFundamentalFigure[];
  [Country]: string;
  [Isin]: string;
  [ListingName]: string;
}

// Do not use GetValuesFromFields anymore, sdkService.cdapiMappedRequest$ can make both the request, mapping and typing in one go
export function GetValuesFromFields<
  TField extends keyof FieldTypeMap,
>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  fields: readonly TField[]
): { [K in TField]: FieldTypeMap[K] } { // the return type is mapped from the fields
  if (!data) {
    return {} as { [K in TField]: FieldTypeMap[K] };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getValueFromPath = (data: any, path: string): any => {
    const keys = path.split('.');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any
    return keys.reduce((acc, key) => (acc ? (acc)[key] : undefined), data);
  };

  return fields.reduce((result, fieldPath) => {
    const path = fieldPath as string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
    (result as any)[path] = getValueFromPath(data, path);
    return result;
  }, {} as { [K in TField]: FieldTypeMap[K] });
}



