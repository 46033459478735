import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { DropdownModule } from '@infront/webtrader-app/app/shared/dropdown/dropdown.module';
import { ConfirmDialogModule } from '@infront/webtrader-app/app/util/components/confirm-dialog/confirm-dialog.module';
import { NewDialogModule } from '@infront/webtrader-app/app/util/components/new-dialog/new-dialog.module';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';
import { SearchModule } from '../search/search.module';
import { AgGridWrapperModule } from '../shared/ag-grid-wrapper/ag-grid-wrapper.module';
import { DropdownWatchlistComponent } from './dropdown-watchlist/dropdown-watchlist.component';
import { WatchlistsComponent } from './watchlists.component';

@NgModule({
  declarations: [
    WatchlistsComponent,
    DropdownWatchlistComponent,
  ],
  imports: [
    CommonModule,
    NewDialogModule,
    ConfirmDialogModule,
    DropdownModule,
    MatIconModule,
    MatDialogModule,
    SearchModule,
    AgGridWrapperModule,
    I18nTranslateModule.forResourceSet({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en']
    }),
  ],
  providers: [],
})
export class WatchlistsModule { }
