import { ChangeDetectionStrategy, Component, computed, inject, signal } from '@angular/core';
import { Infront, InfrontSDK } from '@infront/sdk';
import { AnalyticsBoardMembersWidget, AnalyticsBoardMembersWidgetOptions } from '@infront/wtk/widgets/analytics-board-members';
import { AnalyticsCompanyDescriptionWidget, AnalyticsCompanyDescriptionWidgetOptions } from '@infront/wtk/widgets/analytics-company-description';
import { AnalyticsEstimatesBarWidget, AnalyticsEstimatesBarWidgetOptions } from '@infront/wtk/widgets/analytics-estimates-bar';
import { AnalyticsGprvWidget, AnalyticsGprvWidgetOptions } from '@infront/wtk/widgets/analytics-gprv';
import { AnalyticsGprvScoreCardWidget, AnalyticsGprvScoreCardWidgetOptions } from '@infront/wtk/widgets/analytics-gprv-score-card';
import { AnalyticsKeyFiguresWidget, AnalyticsKeyFiguresWidgetOptions } from '@infront/wtk/widgets/analytics-key-figures';
import { AnalyticsMiniCardsWidget, AnalyticsMiniCardsWidgetOptions } from '@infront/wtk/widgets/analytics-mini-cards';

import { AnalyticsMiniChartWidget, AnalyticsMiniChartWidgetOptions } from '@infront/wtk/widgets/analytics-mini-chart';
import { AnalyticsTopOfficersWidget, AnalyticsTopOfficersWidgetOptions } from '@infront/wtk/widgets/analytics-top-officers';
import { AnalyticsTopShareholdersWidget, AnalyticsTopShareholdersWidgetOptions } from '@infront/wtk/widgets/analytics-top-shareholders';
import { ChartWidget, ChartWidgetOptions, LegendItemClickType, Period, SecondaryColorType, SelectableDataType } from '@infront/wtk/widgets/chart';
import { MarketCapEur, SectorName, SubSectorName } from '../../shared/models/cdapi';
import { globalCompanyDataSource, globalCompanyDescriptionSource, globalEstimateSource, globalFundamentalSource, globalKeyfigureSource } from '../../shared/models/settings';
import { createWidget } from '../../shared/wtk-widget-wrapper/wtk-widget-wrapper.model';
import { column1Width, column2Width } from '../company.model';
import { CompanyOverviewService } from './company-overview.service';

@Component({
  selector: 'app-company-overview',
  templateUrl: './company-overview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CompanyOverviewService]
})
export class CompanyOverviewComponent {
  private readonly companyOverviewService = inject(CompanyOverviewService);

  readonly isin = this.companyOverviewService.isin;
  readonly instrument = this.companyOverviewService.instrument;

  // signals from CDAPI fetched through SDK autogenerated CDAPI access requests
  readonly marketCapEur = computed(() => this.companyOverviewService.cdapiGetEquityCompanies()?.[MarketCapEur]);
  readonly sectorName = computed(() => this.companyOverviewService.cdapiGetEquityCompanies()?.[SectorName]);
  readonly subSectorName = computed(() => this.companyOverviewService.cdapiGetEquityCompanies()?.[SubSectorName]);

  // signals from CDAPI fetched through SDK SymbolData fields request
  readonly cdApiDataFromFields = this.companyOverviewService.cdApiDataFromFields;

  // widgets
  readonly shareholdersWidget = computed(() => createWidget(
    AnalyticsTopShareholdersWidget,
    AnalyticsTopShareholdersWidgetOptions,
    {
      instrument: this.companyOverviewService.instrument(),
      source: globalCompanyDataSource,
    }
  ));

  readonly estimatesBarWidget = computed(() => createWidget(AnalyticsEstimatesBarWidget, AnalyticsEstimatesBarWidgetOptions, { instrument: this.companyOverviewService.instrument() }));

  readonly boardMembersWidget = computed(() => createWidget(
    AnalyticsBoardMembersWidget,
    AnalyticsBoardMembersWidgetOptions, {
    instrument: this.companyOverviewService.instrument(),
    source: globalCompanyDataSource,
  }
  ));

  readonly topOfficersWidget = computed(() => createWidget(
    AnalyticsTopOfficersWidget,
    AnalyticsTopOfficersWidgetOptions,
    {
      instrument: this.companyOverviewService.instrument(),
      source: globalCompanyDataSource,
    }
  ));

  readonly analyticsGprvScoreCardWidget = computed(() => createWidget(
    AnalyticsGprvScoreCardWidget,
    AnalyticsGprvScoreCardWidgetOptions,
    {
      instrument: this.companyOverviewService.instrument(),
      ltmSource: globalKeyfigureSource,
    }
  ));

  readonly companyDescriptionWidget = computed(() => createWidget(
    AnalyticsCompanyDescriptionWidget,
    AnalyticsCompanyDescriptionWidgetOptions,
    {
      instrument: this.companyOverviewService.instrument(),
      source: globalCompanyDescriptionSource,
    }
  ));

  readonly analyticsGprvWidget = computed(() => createWidget(
    AnalyticsGprvWidget,
    AnalyticsGprvWidgetOptions,
    {
      instrument: this.companyOverviewService.instrument(),
      showTable: false,
      ltmSource: globalKeyfigureSource,
    }
  ));

  readonly analyticsMiniCardsTop = computed(() => {
    const instrument = this.companyOverviewService.instrument();
    if (!instrument) {
      return undefined;
    }

    const figureType = (value: InfrontSDK.CoreDataAPI.CdapiEquityCompanyFundamentalFigure | undefined) => value as unknown as InfrontSDK.FundamentalsFigure;

    return createWidget(AnalyticsMiniCardsWidget, AnalyticsMiniCardsWidgetOptions, {
      instrument, canRequestData: false, reportType: Infront.FundamentalsReportType.ANNUAL,
      fundamentalData: { FundamentalKPICalcBvps: figureType(this.companyOverviewService.bvps()), FundamentalKPIDpsOrd: figureType(this.companyOverviewService.dps()), FundamentalKPICalcEps: figureType(this.companyOverviewService.eps()), FundamentalKPICalcSalesPs: figureType(this.companyOverviewService.salesPs()) },
      fundamentalFields: [InfrontSDK.CdapiFundamentalKPIField.FundamentalKPICalcBvps, InfrontSDK.CdapiFundamentalKPIField.FundamentalKPIDpsOrd, InfrontSDK.CdapiFundamentalKPIField.FundamentalKPICalcEps, InfrontSDK.CdapiFundamentalKPIField.FundamentalKPICalcSalesPs]
    });
  });

  readonly analyticsMiniCardsBottom = computed(() => createWidget(AnalyticsMiniCardsWidget, AnalyticsMiniCardsWidgetOptions, {
    instrument: this.companyOverviewService.instrument(), canRequestData: true, reportType: Infront.FundamentalsReportType.ANNUAL
  }));

  // Performance Chart
  chartSelectablePeriods = ['YTD', '1M', '3M', '6M', '1Y', '3Y'];
  selectedChartPeriodIndex = 4;

  readonly chartWidgetRef = signal<ChartWidget | undefined>(undefined);

  readonly chartWidget = computed(() => {
    const instrument = this.companyOverviewService.instrument();
    if (!instrument) {
      return undefined;
    }
    return createWidget(ChartWidget, {
      ... new ChartWidgetOptions(),
      ...{
        instruments: [instrument],
        selectablePeriods: this.chartSelectablePeriods,
        defaultPeriod: this.chartSelectablePeriods[this.selectedChartPeriodIndex],
        showVolume: false,
        streaming: false,
        mainDataType: SelectableDataType.PERFORMANCE,
        secondaryColorType: SecondaryColorType.NEXT,
        secondaryColorTransparency: 1,
        labelAlpha: 'DD',
        legendItemClick: LegendItemClickType.NONE,
        cursorEventsEnabled: false,
      }
    });
  });


  readonly miniChartSalesIncomeMarginWidget = computed(() => {
    const instrument = this.companyOverviewService.instrument();
    if (!instrument) {
      return undefined;
    }
    return createWidget(AnalyticsMiniChartWidget, AnalyticsMiniChartWidgetOptions, {
      instrument,
      fields: ['NET_SALES', 'NET_INCOME', 'NET_PROFIT_MARGIN'],
      reportType: 'ANNUAL',
      fundamentalSource: globalFundamentalSource,
      ltmSource: globalKeyfigureSource,
      estimateSource: globalEstimateSource,
      fundamentalPeriodCount: 5,
      // estimatePeriodCount: 2, // activate if requested
      colors: [
        { ltm: '#119dda', fundamental: '#119dda', estimate: '#119dda' },
        { ltm: '#30cb6e', fundamental: '#30cb6e', estimate: '#30cb6e' },
        '#fbc246',
      ],
    });
  });

  readonly miniChartPEWidget = computed(() => {
    const instrument = this.companyOverviewService.instrument();
    if (!instrument) {
      return undefined;
    }
    return createWidget(AnalyticsMiniChartWidget, AnalyticsMiniChartWidgetOptions, {
      instrument,
      fields: ['PRICE_EARNINGS_RATIO'],
      reportType: 'ANNUAL',
      fundamentalSource: globalFundamentalSource,
      ltmSource: globalKeyfigureSource,
      estimateSource: globalEstimateSource,
      fundamentalPeriodCount: 3,
    });
  });


  readonly keyFiguresWidget = computed(() => {
    return createWidget(AnalyticsKeyFiguresWidget, AnalyticsKeyFiguresWidgetOptions,
      {
        instrument: { isin: this.isin() } as Infront.Instrument,
        fields: [
          'NET_SALES',
          'GROWTH_NET_SALES',
          'NET_PROFIT',
          'NET_PROFIT_MARGIN',
          'EARNINGS_PER_SHARE',
          'EARNINGS_PER_SHARE_GROWTH_1_YEAR',
          'PRICE_EARNINGS_RATIO',
          'PRICE_BOOKVALUE_RATIO',
          'RETURN_ON_EQUITY',
          'ENTERPRISE_VALUE_EBITDA_RATIO',
          'DIVIDEND_YIELD',
          'DIVIDEND_PAYOUT_RATIO',
        ],
        fundamentalSource: globalFundamentalSource,
        ltmSource: globalKeyfigureSource,
        estimateSource: globalEstimateSource,
        fundamentalPeriodCount: 3,
        showInlineHeaders: false,
      });
  });

  readonly column1Width = column1Width;
  readonly column2Width = column2Width;

  selectedMarketTabIndex = 0;

  onSelectedMarketTabChange(index: number): void {
    this.selectedMarketTabIndex = index;
  }

  onSelectedChartTimePeriodChange(index: number): void {
    const chartPeriodsMap: { [key: string]: Period } = {
      'YTD': Period.YTD,
      '1M': Period.ONE_MONTH,
      '3M': Period.THREE_MONTHS,
      '6M': Period.SIX_MONTHS,
      '1Y': Period.ONE_YEAR,
      '3Y': Period.THREE_YEARS,
    };

    this.selectedChartPeriodIndex = index;
    if ((index < 0) || (index > this.chartSelectablePeriods.length)) {
      return;
    }
    const period = this.chartSelectablePeriods[index];
    const chartPeriod = chartPeriodsMap[period];
    if (!period || (chartPeriod == undefined)) {
      return;
    }
    this.chartWidgetRef()?.periodSelected(chartPeriod);
  }
}
