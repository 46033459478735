<div class="wt-analytics-instrument-header" *ngIf="data$ | async as data">
  <div class="wt-flex-row wt-flex-align-middle">
    <div class="wt-flex-column wt-flex-collapse wt-flex-shrink">
      <div class="wt-analytics-instrument-header-flag">
        <img [src]="data.flagPath" class="wt-analytics-instrument-header-flag-icon" [alt]="data['country.name']">
      </div>
    </div>

    <div class="wt-flex-column wt-flex-shrink">
      <div class="wt-analytics-instrument-header-name">
        {{ data['company_basic.name'] || data['issuer.common.name'] || data['listing.common.name'] }}
      </div>
    </div>

    <div class="wt-flex-column wt-flex-shrink wt-analytics-instrument-header__isin">
      <div class="wt-analytics-instrument-header-key-label">
        {{ 'FIELD.ISIN' | translate }}
      </div>
      <div class="wt-analytics-instrument-header-key-value" (click)="copyIsinToClipboard(data.isin, $event)">
        {{ data.isin }}
        <wt-copy-to-clipboard></wt-copy-to-clipboard>
      </div>
    </div>

    <div class="wt-flex-column wt-flex-shrink">
      <div class="wt-analytics-instrument-header-key-label">
        {{ 'FIELD.KEY_FIGURE_MARKET_CAP_EUR' | translate }}
      </div>
      <div class="wt-analytics-instrument-header-key-value">
        <wt-big-number [value]="data['issuer_keyfigure.equity.market_capitalization_eur']"></wt-big-number>
      </div>
    </div>

    <div class="wt-flex-column wt-flex-shrink wt-analytics-instrument-header__sector">
      <div class="wt-analytics-instrument-header-key-label">
        {{ 'FIELD.SECTOR' | translate }}
      </div>
      <div class="wt-analytics-instrument-header-key-value">
        {{ data['gics_sub_industry.name'] }}
        <mat-icon class="material-icons-outlined" fontIcon="info"
          (click)="openSectorsOverlay(data['gics_sub_industry.code'])"></mat-icon>
      </div>
    </div>
  </div>
</div>