import { MarketCapEur, PeriodKPIFigures, SectorName, SnapshotKPIFigures, SubSectorName } from '../../shared/models/cdapi';

export const CdapiGetEquityCompaniesFields = [MarketCapEur, SectorName, SubSectorName] as const;
export const CdapiGetEquityCompanyFundamentalsPeriodKPIsFields = [PeriodKPIFigures] as const;
export const CdapiGetEquityCompanyFundamentalsSnapshotKPIsFields = [SnapshotKPIFigures] as const;





