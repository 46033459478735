<div class="wt-splash-layer" [@showSplash]="showSplash" *ngIf="isSplash">
  <wt-splash class="absolute-center wt-splash"></wt-splash>
</div>

<header class="wt-header">
  <wt-header></wt-header>
</header>

<section class="wt-flex wt-payload">
  <router-outlet></router-outlet>
</section>