import { inject, Injectable } from '@angular/core';
import { Infront, InfrontSDK, InfrontUtil } from '@infront/sdk';
import { LogService } from '@vwd/ngx-logging';
import { combineLatest, finalize, map, NEVER, Observable, of, ReplaySubject, shareReplay, Subscriber, switchMap, take, tap } from 'rxjs';
import { SdkService } from '../services/sdk.service';
import { isInstrument, sdkOnError } from '../shared/util';


export interface WatchlistItemChanged {
  item: InfrontSDK.Watchlist;
  index: number;
}

export interface WatchlistsUpdate {
  watchlists: InfrontSDK.Watchlist[];
  watchlistChanged?: WatchlistItemChanged;
}

export type WatchlistOrId = InfrontSDK.Watchlist | string | undefined;

export type WatchlistByProvider = { [provider: number]: InfrontSDK.Watchlist[]; };


@Injectable({
  providedIn: 'root',
})
export class WatchlistService {
  private readonly logger = inject(LogService).openLogger('services/watchlist');
  private readonly sdkService = inject(SdkService);

  private watchlistInfrontSDKUnsubscribe: InfrontSDK.Unsubscribe | undefined;

  readonly watchlistTitlesAction = new ReplaySubject<string[]>(1);
  readonly watchlistTitles$ = this.watchlistTitlesAction.asObservable();

  private readonly navigateAction = new ReplaySubject<{ isin: string, page: string }>(1);
  navigate$ = this.navigateAction.asObservable();


  readonly watchlists$ = this.sdkService.sdk$.pipe(
    switchMap((sdk) =>
      new Observable((subscriber: Subscriber<WatchlistsUpdate>) => {
        const watchlistOptions: InfrontSDK.WatchListsObservableArrayOptions = {
          provider: true,
          subscribe: true,
          onData: (data: Infront.ObservableArray<InfrontSDK.Watchlist>) => {
            data.observe({
              itemAdded: () => {
                this.logger.debug('Item Added', data.data);
                subscriber.next({ watchlists: data.data });
              },
              itemRemoved: () => {
                this.logger.debug('Item Removed', data.data);
                subscriber.next({ watchlists: data.data });
              },
              reInit: () => {
                this.logger.debug('Re-initialized', data.data);
                subscriber.next({ watchlists: data.data });
              },
              itemChanged: (item: InfrontSDK.Watchlist, index: number) => {
                this.logger.debug('Item Changed', { item, index });
                subscriber.next({
                  watchlists: data.data,
                  watchlistChanged: { item, index }
                });
              },
              itemMoved: () => {
                this.logger.debug('Item Moved', data.data);
                subscriber.next({ watchlists: data.data });
              },
            });
            // subscriber.next({ watchlists: data.data }); // Initial, but not really required since first should call reInit (?)
          },
          onError: (error: InfrontSDK.ErrorBase<{ validation: string[], options: InfrontSDK.DataRequestOptions; }>) => {
            this.logger.error('Error', error);
            throw new Error(`SDK error:${error.title} ${error.parameters?.validation?.[0]}`);
          },
        };
        this.logger.debug('Watchlist SDK Request', watchlistOptions);
        this.watchlistInfrontSDKUnsubscribe = sdk.get(InfrontSDK.Requests.watchListsAsObservableArray(watchlistOptions));
      })
    ),
    // KEEP DEEPCOPY, otherwise this will have huge sideeffects on the chained Observables
    map((watchlistsUpdate) => InfrontUtil.deepCopy(watchlistsUpdate) as WatchlistsUpdate),
    map((watchlistsUpdate) => {
      // catch falsey, failback empty array
      watchlistsUpdate.watchlists ??= [];
      return watchlistsUpdate;
    }),
    map((watchlistsUpdate: WatchlistsUpdate) => {
      // filter invalid symbols
      const watchlists = watchlistsUpdate?.watchlists;
      watchlists.forEach((wl) => {
        if (wl.items?.length) {
          wl.items = wl.items.filter((symbolId) => isInstrument(symbolId));
        }
      });
      if (watchlistsUpdate?.watchlistChanged) {
        watchlistsUpdate.watchlistChanged.item.items = watchlistsUpdate.watchlistChanged.item.items?.filter((symbolId) => isInstrument(symbolId));
      }
      this.watchlistTitlesAction.next(watchlistsUpdate.watchlists.map((wl) => wl.title));
      return watchlistsUpdate;
    }),
    finalize(() => this.watchlistInfrontSDKUnsubscribe?.()),
    shareReplay(1),
  );

  readonly watchlistsByProviders$ = this.watchlists$.pipe(
    map(({ watchlists }) => {
      return watchlists.reduce<WatchlistByProvider>((wlsByProvider, wl) => {
        const provider = wl.provider ?? 0;
        wlsByProvider[provider] ??= [];
        wlsByProvider[provider].push(wl);
        return wlsByProvider;
      }, {});
    }),
    shareReplay(1),
  );

  private requestedWatchlistIdAction = new ReplaySubject<string | undefined>(1);

  requestWatchlist(watchlistId: string | undefined): void {
    if (watchlistId == undefined) {
      return;
    }
    this.requestedWatchlistIdAction.next(watchlistId);
  }



  private lastSelectedWatchlist: InfrontSDK.Watchlist | undefined;

  get selectedWatchlist(): InfrontSDK.Watchlist | undefined {
    return this.lastSelectedWatchlist;
  }

  readonly selectedWatchlist$ = combineLatest([this.watchlists$, this.requestedWatchlistIdAction]).pipe(
    switchMap(([watchlists, requestedWatchlistId]) => {
      if (!watchlists.watchlists?.length) {
        return of(undefined);
      }
      const selectedWatchlist = watchlists.watchlists.find((wl) => wl.id === requestedWatchlistId);
      if (!selectedWatchlist) {
        return NEVER;
      }
      this.lastSelectedWatchlist = selectedWatchlist ?? watchlists.watchlists[0];
      return of(this.lastSelectedWatchlist);
    })
  );


  getWatchlistId(watchlistOrId: WatchlistOrId): string | undefined {
    return typeof watchlistOrId === 'object' ? watchlistOrId.id : watchlistOrId;
  }

  readonly saveWatchlist$ = (title: string, provider?: number, symbolId: InfrontSDK.SymbolId | InfrontSDK.SymbolId[] = []): Observable<boolean> => {
    if (!title) {
      return of(false);
    }
    const saveWatchlistContentOptions: Omit<InfrontSDK.WatchListContentOptions, 'onData' | 'listNameUpdated'> = {
      action: InfrontSDK.WatchListContentAction.SaveWatchList,
      listName: title,
      provider,
      symbolId: typeof symbolId === 'object'
        ? Array.isArray(symbolId) // NOSONAR is good to read as it is!
          ? symbolId
          : [symbolId] // single symbolId, non array, needs to be casted
        : [],
    };
    return this.sdkService.getObject$(InfrontSDK.Requests.watchListContent, saveWatchlistContentOptions);
  };

  readonly deleteWatchlist$ = (title: string, provider?: number): Observable<boolean> => {
    if (!title) {
      return of(false);
    }
    const deleteWatchlistOptions: Omit<InfrontSDK.WatchListContentOptions, 'onData' | 'symbolId' | 'listNameUpdated'> = {
      action: InfrontSDK.WatchListContentAction.DeleteWatchList,
      listName: title,
    };
    if (provider) {
      deleteWatchlistOptions.provider = provider;
    }
    return this.sdkService.getObject$(InfrontSDK.Requests.watchListContent, deleteWatchlistOptions);
  };

  readonly renameWatchlist$ = (title: string, newTitle: string, provider?: number): Observable<boolean> => {
    if (!title || !newTitle || title === newTitle) {
      return of(false);
    }
    const renameWatchlistOptions: Omit<InfrontSDK.WatchListContentOptions, 'onData' | 'symbolId'> = {
      action: InfrontSDK.WatchListContentAction.RenameWatchList,
      listName: title,
      listNameUpdated: newTitle,
      provider
    };
    return this.sdkService.getObject$(InfrontSDK.Requests.watchListContent, renameWatchlistOptions);
  };

  readonly providerAccess$ = (provider: number, accessType: 'read' | 'write') => {
    return this.watchlistProviderAccess$.pipe(
      map(accessData => {
        const access = accessType === 'write' ? provider in accessData.write : provider in accessData.read;
        return access;
      }),
      take(1),
    );
  };

  readonly watchlistProviderAccess$ = this.sdkService.getObject$(InfrontSDK.watchlistProviderAccess).pipe(
    tap((accessData) => this.logger.info('Access Data', accessData)),
    shareReplay(1));

  updateWatchlist(isin: string, action: 'add' | 'remove'): void {
    if (!this.lastSelectedWatchlist) {
      return;
    }
    const { title, provider } = this.lastSelectedWatchlist;
    const obs$ = action === 'add' ? this.addSymbolIdToWatchlist(title, { isin }, provider, sdkOnError(this.logger)) : this.removeSymbolIdFromWatchlist(title, { isin }, provider);
    obs$.pipe(take(1)).subscribe();
  }

  readonly addSymbolIdToWatchlist = (title: string, symbolId: InfrontSDK.SymbolId | InfrontSDK.SymbolId[], provider?: number, onError = (error: InfrontSDK.ErrorBase) => { }): Observable<boolean> => {
    if (!title || !symbolId) {
      return of(false);
    }
    const addSymbolIdToWlOptions: Omit<InfrontSDK.WatchListContentOptions, 'onData' | 'listNameUpdated'> = {
      action: InfrontSDK.WatchListContentAction.AddSymbolId,
      listName: title,
      symbolId,
      provider,
      onError,
    };
    return this.sdkService.getObject$(InfrontSDK.Requests.watchListContent, addSymbolIdToWlOptions);
  };

  readonly removeSymbolIdFromWatchlist = (title: string, symbolId: InfrontSDK.SymbolId | InfrontSDK.SymbolId[], provider?: number): Observable<boolean> => {
    if (!title || !symbolId) {
      return of(false);
    }
    const deleteSymbolIdFromWlOptions: Omit<InfrontSDK.WatchListContentOptions, 'onData' | 'listNameUpdated'> = {
      action: InfrontSDK.WatchListContentAction.RemoveSymbolId,
      listName: title,
      symbolId,
      provider
    };
    return this.sdkService.getObject$(InfrontSDK.Requests.watchListContent, deleteSymbolIdFromWlOptions);
  };

  // Determines if the watchlist should be disabled for write operations. Returns true if no write access and is a provider watchlist, otherwise false. Used for disabling in context menu.
  readonly shouldDisableWriteAccessForWatchlist$ = (watchlist: InfrontSDK.Watchlist | undefined) => {
    if (watchlist?.provider == undefined) {
      return of(false);
    }
    return this.providerAccess$(watchlist.provider, 'write').pipe(map(value => !value));
  };

  navigate(params: { isin: string, page: string }): void {
    this.navigateAction.next(params);
  }
}
