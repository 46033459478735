import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { InfrontSDK } from '@infront/sdk';
import { LogService } from '@vwd/ngx-logging';
import { combineLatest, map, switchMap } from 'rxjs';

import { filterUndefined } from '../../../../../webtrader-app/src/app/util/rxjs';
import { CommonRequestsService } from '../../services/common-requests.service';
import { CountryFlagService } from '../../services/country-flag.service';
import { SdkService } from '../../services/sdk.service';
import { Country, GicsSectorName } from '../models/cdapi';
import { injectParams$, mergeObjectArrays } from '../util-rxjs';
import { copyTextToClipboard } from '../../../../../webtrader-app/src/app/util/utils';
import { HeaderService } from '../../layout/header/header.service';


@Component({
  selector: 'wt-analytics-instrument-header',
  templateUrl: './analytics-instrument-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CountryFlagService]
})
export class AnalyticsInstrumentHeaderComponent {
  private readonly isin$ = injectParams$('isin');
  private readonly sdkService = inject(SdkService);
  private readonly commonRequestsService = inject(CommonRequestsService);
  private readonly countryFlagService = inject(CountryFlagService);
  private readonly logger = inject(LogService).openLogger('shared/analytics-instrument-header');
  readonly headerService = inject(HeaderService);

  private readonly gicsSector$ = (isin: string) =>
    this.sdkService.cdapiMappedRequest$(InfrontSDK.CoreDataAPI.cdapiGetEquityClassificationGicsSectors,
      { isin, fields: [GicsSectorName] });

  readonly data$ = this.isin$.pipe(
    filterUndefined(),
    switchMap(isin =>
      combineLatest([
        this.commonRequestsService.gicsSubIndustries$(isin),
        this.commonRequestsService.countryAndName$(isin),
        this.gicsSector$(isin),
      ]).pipe(
        mergeObjectArrays(),
        map(merged => ({ ...merged, isin, flagPath: this.countryFlagService.getCountryFlagPath(merged[Country], 48) })))
    )
  );

  copyIsinToClipboard(isin: string, event: Event): void {
    copyTextToClipboard(isin, event, this.logger);
  }

  async openSectorsOverlay(gicsSubIndustryCode: string): Promise<void> {
    if (!gicsSubIndustryCode) {
      return;
    }
    await this.headerService.openOverlay('sectors', { gicsSubIndustryCode });
  }

}

