import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { tap } from 'rxjs';

import { WatchlistService } from '../../watchlists/watchlist.service';
import { HeaderService } from './header.service';
import { ThemeService } from '../../services/theme.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'wt-header',
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  private readonly router = inject(Router);
  private readonly dialog = inject(MatDialog);
  readonly headerService = inject(HeaderService);
  private readonly watchlistService = inject(WatchlistService);
  readonly theme = inject(ThemeService).theme;
  readonly supportedThemes = inject(ThemeService).supportedThemes;
  readonly development = environment.development;

  // header is listening to watchlistService navigate$ since it has control over the dialog that needs to be closed before navigation occurs
  readonly navigate$ = this.watchlistService.navigate$.pipe(
    tap(queryParams => {
      this.dialog.closeAll();
      void this.router.navigate(['/'], { queryParams });
    }));

}
