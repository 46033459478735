<div class="wt-analytics-window" style="width: 1000px; height: 780px;">
  <div class="wt-analytics-window__header">
    Financial Statements
  </div>

  <div class="wt-analytics-window__content">
    <div class="wt-company-financial-statements">
      <wt-wtk-widget-wrapper class="wt-company-financial-statements__container"
        *ngIf="fincancialStatementWidget() as widget"
        [widget]="widget">
      </wt-wtk-widget-wrapper>
    </div>
  </div>
</div>