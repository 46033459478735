import { NgModule } from '@angular/core';
import { RouterModule, provideRouter, type Routes } from '@angular/router';
import { CompanyChartsComponent } from './charts/company-charts.component';
import { CompanyComponent } from './company.component';
import { CompanyCompareComponent } from './compare/company-compare.component';
import { CompanyEsgComponent } from './esg/company-esg.component';
import { CompanyEstimatesComponent } from './estimates/company-estimates.component';
import { CompanyFinancialStatementsComponent } from './financial-statements/company-financial-statements.component';
import { CompanyGprvComponent } from './gprv/company-gprv.component';
import { CompanyKeyfiguresComponent } from './keyfigures/company-keyfigures.component';
import { CompanyOverviewComponent } from './overview/company-overview.component';
import { CompanySectorComponent } from './sector/company-sector.component';

export const DefaultChildRoute = 'overview';


const routes: Routes = [
  {
    path: ':isin',
    component: CompanyComponent,
    children: [
      {
        path: 'overview',
        component: CompanyOverviewComponent,
      },
      {
        path: 'keyfigures',
        component: CompanyKeyfiguresComponent,
      },
      {
        path: 'financial-statements',
        component: CompanyFinancialStatementsComponent,
      },
      {
        path: 'estimates',
        component: CompanyEstimatesComponent,
      },
      {
        path: 'charts',
        component: CompanyChartsComponent,
      },
      {
        path: 'esg',
        component: CompanyEsgComponent,
      },
      {
        path: 'sector',
        component: CompanySectorComponent,
      },
      {
        path: 'gprv',
        component: CompanyGprvComponent,
      },
      {
        path: 'compare',
        component: CompanyCompareComponent,
      },
    ]
  },

];

@NgModule({
  exports: [
    RouterModule
  ],
  providers: [
    provideRouter(routes),
  ]
})
export class CompanyRoutingModule { }
