import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AnalyticsSectorInformationComponent } from './analytics-sector-information.component';
import { WTKWidgetWrapperModule } from '../wtk-widget-wrapper/wtk-widget-wrapper.module';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  exports: [
    AnalyticsSectorInformationComponent,
  ],
  declarations: [
    AnalyticsSectorInformationComponent,
  ],
  imports: [
    CommonModule,

    MatIconModule,

    WTKWidgetWrapperModule,
  ],
})
export class AnalyticsSectorInformationModule { }
