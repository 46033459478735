import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-company-charts',
  templateUrl: './company-charts.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyChartsComponent {

}
