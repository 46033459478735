

import { Injectable, inject } from '@angular/core';
import { CountryFlagMap, CountryFlagMapValues } from '@infront/wtk/utils/country-flags';
import { FormattingService } from '@vwd/ngx-i18n';

const COUNTRY_CODE_PREFIX: Readonly<string> = 'CODE_';

interface CountryFlagMapType {
  [key: string]: string;
}

const CountryFlagMapImport: CountryFlagMapType = CountryFlagMap;

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  private readonly format: FormattingService = inject(FormattingService);

  readonly twoDecimalsPercent = (value: number | undefined): string => {
    return value !== undefined ? `${this.format.formatNumber(+value, '1.2')}%` : 'N/A';
  };

  readonly upDownColor = (value: number | undefined): string[] => {
    if (!value) {
      return [];
    }
    if (value < 0) {
      return ['value--negative'];
    }
    return ['value--positive'];
  };


  readonly getCountryFileNameForFlag = (
    country: string | number | undefined,
    allowFallbackFlag = true,
    fallbackFlag: string = CountryFlagMap.GLOBAL
  ): string | undefined => {
    let fileName;

    if (typeof country === 'string') {
      if (CountryFlagMapValues.includes(country)) {
        fileName = country;
      } else {
        const replaceWhitespaceWithUnderscore = new RegExp(/\s/, 'g');
        const removeSpecialCharacters = new RegExp(/[^\W]/, 'g'); // A-Za-z0-9_
        fileName = (
          CountryFlagMapImport[country]
          || CountryFlagMapImport[country.toUpperCase().replace(replaceWhitespaceWithUnderscore, '_').replace(removeSpecialCharacters, '')]
        );
      }
    } else if (typeof country === 'number') {
      fileName = CountryFlagMapImport[COUNTRY_CODE_PREFIX + String(country)];
    }

    if (!fileName && allowFallbackFlag) {
      fileName = fallbackFlag;
    }

    return fileName;
  };

}













