<div class="wt-tabs-content" *ngIf="vm() as vm">
  <!-- left side windows -->
  <div class="wt-tabs-content__col">
    <wt-analytics-window class="" [title]="'Company Scores'" [width]="column1Width" [height]="734">
      <div class="wt-analytics-window__esg-top-score">
        <wt-wtk-widget-wrapper [widget]="vm.esgTopScoreWidget"></wt-wtk-widget-wrapper>
      </div>

      <div class="wt-analytics-window__esg-table">
        <wt-wtk-widget-wrapper [widget]="vm.esgTableViewWidget"></wt-wtk-widget-wrapper>
      </div>
    </wt-analytics-window>
  </div>
  <!-- right side windows -->
  <div class="wt-tabs-content__col">
    <wt-analytics-window
      [title]="vm['gics_sector.name'] + ' (Sector level 1)'"
      [width]="column2Width"
      [height]="366">
      <div class="wt-analytics-window__top-score ag-theme-infront">
        <wt-wtk-widget-wrapper
          [widget]="vm.esgSectorScoresWidget">
        </wt-wtk-widget-wrapper>
      </div>
    </wt-analytics-window>

    <wt-analytics-window
      [title]="vm['gics_industry_group.name'] + ' (Sector level 2)'"
      [width]="column2Width"
      [height]="366">
      <div class="wt-analytics-window__best-companies ag-theme-infront">
        <wt-wtk-widget-wrapper
          [widget]="vm.esgBestCompaniesWidget">
        </wt-wtk-widget-wrapper>
      </div>
    </wt-analytics-window>
  </div>
</div>