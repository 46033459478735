import type { ComponentType } from '@angular/cdk/portal';
import type { MatDialogConfig } from '@angular/material/dialog';

import type { SectorsMatData } from '../../sectors/sectors.model';

export interface OverlayDefn<D = unknown> {
  config: MatDialogConfig<D>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loadModule?: () => Promise<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loadComponent: () => Promise<ComponentType<any>>;
}

export const overlayDefaults: Partial<OverlayDefn> = {
  config: {
    height: '90vh',
    width: '80vw',
    maxWidth: '980px',
    maxHeight: '800px',
  }
};

export const overlayDefs = {
  screener: {
    config: {},
    loadModule: () => import('../../screener/screener.module').then(m => m.ScreenerModule),
    loadComponent: () => import('../../screener/screener.component').then(m => m.ScreenerComponent),
  },
  sectors: {
    config: {
      height: '600px',
      width: '500px',
    },
    loadModule: () => import('../../sectors/sectors.module').then(m => m.SectorsModule),
    loadComponent: () => import('../../sectors/sectors.component').then(m => m.SectorsComponent),
  },
  watchlists: {
    config: {},
    loadModule: () => import('../../watchlists/watchlists.module').then(m => m.WatchlistsModule),
    loadComponent: () => import('../../watchlists/watchlists.component').then(m => m.WatchlistsComponent),
  },
  analysts: {
    config: {
      height: '600px',
      width: '400px',
    },
    loadModule: () => import('../../shared/analysts/analysts.module').then(m => m.AnalystsModule),
    loadComponent: () => import('../../shared/analysts/analysts.component').then(m => m.AnalystsComponent),
  },
} as const satisfies Readonly<{ [OverlayName: string]: OverlayDefn; }>;

export type MatDataByOverlay = {
  screener: never;
  sectors: SectorsMatData;
  watchlists: never;
  analysts: { isin: string };
};

export type OverlayName = keyof typeof overlayDefs;

export const selectIsinEventName = 'selectIsin';
