import { type PipeTransform, Pipe, inject } from '@angular/core';
import { UtilService } from '../../services/util.service';

@Pipe({
  name: 'classGetter'
})
export class ClassGetterPipe implements PipeTransform {
  private readonly utilService: UtilService = inject(UtilService);

  transform(value: number | undefined, classGetter: keyof UtilService): string[] {
    const classMethod = this.utilService[classGetter];
    const classList = classMethod(value) as string[];
    return classList;
  }
}
