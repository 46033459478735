import { inject, Injectable } from '@angular/core';
import { InfrontSDK } from '@infront/sdk';
import { map } from 'rxjs';
import { CompanyName, Country, GicsIndustryGroupCode, GicsIndustryGroupName, IssuerName, ListingName, MarketCapEur } from '../shared/models/cdapi';
import { typedFields } from '../shared/util';
import { SdkService } from './sdk.service';

@Injectable({
  providedIn: 'root',
})
export class CommonRequestsService {
  private readonly sdkService: SdkService = inject(SdkService);

  countryAndName$ = (isin: string) => this.sdkService.cdapiMappedRequest$(
    InfrontSDK.CoreDataAPI.cdapiGetEquityListings,
    { preferred_trading_venue_strategy_code: 'PRIMARY_TRADING_VENUE' as const, isin, fields: [Country, CompanyName, ListingName, MarketCapEur, IssuerName] }
  );

  gicsIndustryGroups$ = (isin: string) => this.sdkService.cdapiMappedRequest$(InfrontSDK.CoreDataAPI.cdapiGetEquityClassificationGicsIndustryGroups,
    { isin, fields: [GicsIndustryGroupName, GicsIndustryGroupCode] });

  gicsSubIndustries$ = (isin: string) => this.sdkService.cdapiMappedRequest$(InfrontSDK.CoreDataAPI.cdapiGetEquityClassificationGicsSubIndustries,
    { isin, fields: ['gics_sub_industry.code', 'gics_sub_industry.name'] });


  symbolData$ = <T extends InfrontSDK.SymbolField[]>(id: InfrontSDK.SymbolId[], fields: T) => {
    const opts: Partial<InfrontSDK.SymbolDataOptions<InfrontSDK.SymbolId[]>> = { id, fields };

    return this.sdkService.getArray$(InfrontSDK.symbolData, opts).pipe(
      map(symbols => symbols.map(s => typedFields(fields, s)))
    );
  };
}
