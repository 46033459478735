import { Injectable, inject } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarConfig as MatSnackBarConfig } from '@angular/material/legacy-snack-bar';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  readonly snackBar = inject(MatSnackBar);

  _config: MatSnackBarConfig;
  _action = 'OK';

  constructor() {
    this._config = new MatSnackBarConfig();
    this._config.duration = 5000;
  }

  showSuccess(message: string): void {
    this.snackBar.open(message, this._action, this._config);
  }

  showError(message: string): void {
    this.snackBar.open(message, this._action, { ...this._config, panelClass: ['error'], horizontalPosition: 'right', verticalPosition: 'top' });
  }
}
